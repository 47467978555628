import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import { TerritorialAdministrativeUnitField } from '@/modules/administrator/fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`TERRITORIAL_UNIT_ALLOCATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`TERRITORIAL_UNIT_ALLOCATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    tu_name: new StringField(
        'tu_name',
        label('tu_name'),
        { required: true },
        { placeholder: placeholder('tu_name')},
    ),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('territorial_administrative_unit_id') },
    ),
};

export class TerritorialUnitAllocationModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
