import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class FunctionsField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                return new Promise((resolve) => {
                    if (params.dep_id) {
                        ApiService.setBaseUrl({isArchive: false})
                        ApiService.query('department-functions', {
                            params: {
                                query: `department_id eq ${params?.dep_id}`,
                                limit: 100,
                                sort: 'function_name'
                            }
                        })
                        .then(({ data }) => resolve(data.data));
                    } else {
                        resolve([])
                    }
                    
                });
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.function_id,
                    text: record.function_name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
