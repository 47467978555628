import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import BooleanField from '@/shared/fields/boolean-field';
import { CountyField } from '@/shared/common-fields/county-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { OrganisationTypeField } from '@/modules/administrator/fields/organisation-type-field';

const field = (name) => i18n.t(`INSTITUTION.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`INSTITUTION.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`INSTITUTION.PLACEHOLDERS.${name.toUpperCase()}`);


const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField(
        'name',
        field('name'),
        { required: true },
        { placeholder: placeholder('name')},
    ),
    description: new StringField(
        'description',
        field('description'),
        {},
        { placeholder: placeholder('description')},
    ),
    status: new EnumField(
        'status',
        field('status'),
        [
            { value: 'active', text: enumLabel('status', 'active'), type: 'primary', name: 'active' },
            { value: 'inactive', text: enumLabel('status', 'inactive'), type: 'danger' , name: 'inactive'},
        ],
        {},
        { placeholder: placeholder('status')},
    ),
    is_test: new EnumField(
        'is_test',
        field('is_test'),
        [
            { value: 'true', text: enumLabel('is_test', 'yes'), type: 'primary',},
            { value: 'false', text: enumLabel('is_test', 'no'), type: 'danger' },
        ],
        {},
        {
            placeholder: placeholder('is_test'),
        },
    ),
    county_id: CountyField.relationToOne(
        'county_id',
        field('county'),
        { autocompleteRequired: true, firstLetterFilter: true  },
        { placeholder: placeholder('county') },
        [],
        { asyncData: true }
    ),
    locality_id: TerritorialAdministrativeUnitField.relationToOne(
        'locality_id',
        field('locality'),
        { autocompleteRequired: true, firstLetterFilter: true },
        { placeholder: placeholder('locality') },
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
        { asyncData: true}
    ),
    test_org: new BooleanField('is_test', field('test_org')),
    type: OrganisationTypeField.relationToOne(
        'type',
        field('type'),
        { required: true },
        { placeholder: 'type'},
        [{
            key: 'type',
            name: 'locality_id'
        }]
    ),
    external_link: new StringField('external_link', field('external_link'), { url: true, required: false }, {}),
    is_citizen_visible: new BooleanField('is_citizen_visible', field('is_citizen_visible')),
    op_details_text: new StringField('op_details_text', field('op_details_text'), { required: true}),
};

export class OrganisationModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
