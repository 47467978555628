<template>
    <div>
        <st-modal
            id="st-manage-function-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <div v-if="editMode">
                    <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                        {{ $t('FUNCTIONS.ADD_EDIT_FORM.HEADER_EDIT') }}
                    </p>
                </div>
                <div v-else>
                    <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                        {{ $t('FUNCTIONS.ADD_EDIT_FORM.HEADER_ADD') }}
                    </p>
                </div>
            </template>
            <template #body>
                <manage-function-form
                    ref="manage-function-form"
                    :institution="institution"
                    :selectedFunction="selectedFunction"
                    :editMode="editMode"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </manage-function-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageFunction"
                    >
                        <span v-if="editMode"> {{ $t('GENERAL.BUTTON.UPDATE') }}</span>
                        <span v-else> {{ $t('GENERAL.BUTTON.ADD') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ManageFunctionForm from './ManageFunctionForm.vue';

export default {
    name: 'ManageFunction',
    components: {
        ManageFunctionForm
    },
    props: {
        institution: {
            type: Object,
        },
        selectedFunction: {
            type: Object
        },
        editMode: {
            type: Boolean
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manageFunction() {
            this.$refs['manage-function-form'].manageFunction();
        },
        submit() {
            this.$emit('updateFunction');
        }
    }
};
</script>
