<template>
    <div>
        <st-button
            variant="secondary"
            :callback="showModal"
        >
            <i class="fa fa-plus"></i>
            {{ $t('DECISION.BUTTON.ADD_DECISION') }}
        </st-button>
    </div>
</template>

<script>
    export default {
        name: 'DecisionListToolbar',
        methods: {
            showModal() {
                this.$emit('addDecision');
            }
        }
    };
</script>
