<template>
    <div>
        <Loader  v-if="loading['admin/getInstitution']"></Loader>
        <st-page
            :title="title"
            backLink="/institutions"
            :hideBackButton="hideBackButton"
            layout="tab"
        >
            <div v-if="!loading['admin/getInstitution']">
                <b-tabs class="st-tabs" fill lazy v-model="activeTab">
                    <template>
                        <b-tab v-for="tab in tabsList" :key="tab.index">
                            <template #title >
                                <div class="d-flex justify-content-center w-100">
                                    <i :class="['fas', tab.icon]"></i>
                                    <span class="text-uppercase ml-4">{{ $t(tab.titleKey) }}</span>
                                </div>
                            </template>
                            <component
                                :is="tab.componentType"
                                :selectedInstitution="selectedInstitution"
                                @updateInstitutions=updateInstitutions
                            >
                            </component>
                        </b-tab>
                    </template>
                </b-tabs>
            </div>
        </st-page>
    </div>
</template>

<script>
import EditInstitution from '@/modules/administrator/components/institutions/EditInstitution.vue';
import Departments from '@/modules/administrator/pages/DepartmentList.vue';
import Functions from '@/modules/administrator/pages/FunctionList.vue';
import Users from '@/modules/administrator/pages/FunctionaryList.vue';
import CollectorAccounts from '@/modules/administrator/pages/CollectorAccountList.vue';
import Taxes from '@/modules/administrator/components/taxes/Taxes.vue';
import DocumentSeries from '@/modules/administrator/components/document-series/DocumentSeries.vue';
import Responsibilities from '@/modules/administrator/pages/ResponsibilityList.vue';
import DecisionList from '@/modules/administrator/pages/DecisionList.vue';
import UrbanismRegulationsList from '@/modules/administrator/pages/UrbanismRegulationsList.vue';

import { mapGetters, mapActions } from 'vuex';
import { isObjectEmpty } from '../../../../core/helpers/globalMethods';

export default {
    name: 'InstitutionDetails',
    components: {
        EditInstitution,
        Departments,
        Functions,
        Users,
        CollectorAccounts,
        Taxes,
        DocumentSeries,
        Responsibilities,
        DecisionList,
        UrbanismRegulationsList,
    },
    data() {
        return {
            selectedOrganisation: {},
        };
    },
    computed: {
        ...mapGetters({
            institutionAPI: 'administrator/institution',
            orgPermissions: 'administrator/orgList/orgPermissions',
            loading: 'shared/loading'
        }),
        tabs () {
            return [
                {
                    index: 0,
                    name: 'institution-details',
                    titleKey: 'INSTITUTION.TABS.DETAILS',
                    icon: 'fa-edit',
                    componentType: 'edit-institution',
                    hasPermission: this.orgPermissions.hasPermissionToEdit
                },
                {
                    index: 1,
                    name: 'functions',
                    icon: 'fa-id-badge',
                    titleKey: 'INSTITUTION.TABS.FUNCTIONS',
                    componentType: 'functions',
                    hasPermission: this.orgPermissions.hasPermissionToViewFunctions
                },
                {
                    index: 2,
                    name: 'departments',
                    titleKey: 'INSTITUTION.TABS.DEPARTMENTS',
                    icon: 'fa-sitemap',
                    componentType: 'departments',
                    hasPermission: this.orgPermissions.hasPermissionToViewDepartments
                },
                {
                    index: 3,
                    name: 'users',
                    titleKey: 'INSTITUTION.TABS.USERS',
                    icon: 'fa-users',
                    componentType: 'users',
                    hasPermission: this.orgPermissions.hasPermissionToViewUsers
                },
                {
                    index: 4,
                    name: 'taxes',
                    titleKey: 'INSTITUTION.TABS.TAXES',
                    icon: 'fa-hand-holding-usd',
                    componentType: 'taxes',
                    hasPermission: this.orgPermissions.hasPermissionToViewTaxes
                },
                {
                    index: 5,
                    name: 'accounts',
                    titleKey: 'INSTITUTION.TABS.ACCOUNTS',
                    icon: 'fa-credit-card',
                    componentType: 'collector-accounts',
                    hasPermission: this.orgPermissions.hasPermissionToViewBankAccounts
                },
                {
                    index: 6,
                    name: 'document-series',
                    titleKey: 'INSTITUTION.TABS.DOCUMENT_SERIES',
                    icon: 'fa-file-alt',
                    componentType: 'document-series',
                    hasPermission: this.orgPermissions.hasPermissionToViewDocumentSeries
                },
                {
                    index: 7,
                    name: 'responsibilities',
                    titleKey: 'INSTITUTION.TABS.RESONSIBILITIES',
                    icon: 'fa-people-carry',
                    componentType: 'responsibilities',
                    hasPermission: this.orgPermissions.hasPermissionToViewOrgResponsibilities
                },
                {
                    index: 8,
                    name: 'decisions',
                    titleKey: 'INSTITUTION.TABS.DECISIONS',
                    icon: 'fa-gavel',
                    componentType: 'decision-list',
                    hasPermission: this.orgPermissions.hasPermissionToViewDecisions
                },
                {
                    index: 9,
                    name: 'urbanism-regulations',
                    titleKey: 'INSTITUTION.TABS.URBANISM_REGULATIONS',
                    icon: 'fa-list-ul',
                    componentType: 'urbanism-regulations-list',
                    hasPermission: true
                },
            ]
        },
        tabsList () {
            return this.tabs.filter(tab => tab.hasPermission).map((tab, index) => {
                return {
                    ...tab,
                    index,
                }
            })
        },
        activeTab: {
            get() {
                const tabName = this.$route.query?.tab || DEFAULT_TAB;
                return this.tabsList.findIndex((el) => el.name === tabName);
            },
            set(tabIndex) {
                const tab = this.tabsList.find((el) => el.index === tabIndex);
                this.$router.push({
                    name: 'institution',
                    query: { ...this.$route.query, tab: tab.name }
                });
            },
        },
        title() {
            return `${this.$t('INSTITUTION.MAIN_HEADER')} : ${this.selectedInstitution.name}`
        },
        hideBackButton() {
            return !this.orgPermissions.hasPermissionToList;
        },
        selectedInstitution() {
            return this.selectedOrganisation;
        }
    },
    created () {
        if (!isObjectEmpty(this.$route.params)) {
            this.selectedOrganisation = {...this.$route.params}
        } else {
            this.selectedOrganisation.id = this.$route.query.id;
            this.getInstitution(this.$route.query.id)
            .then(() => {
                this.selectedOrganisation = {...this.institutionAPI}
            })
        }
        this.selectOrganisation(this.$route.query.id);
    },
    methods: {
        ...mapActions({
            getInstitution: 'administrator/getInstitution',
            selectOrganisation: 'administrator/orgList/selectOrganisation',
        }),
        updateInstitutions(data) {
            this.selectedOrganisation = data;
        }
    }
};
</script>
