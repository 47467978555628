<template>
    <functionary-account-form
        @submitForm="addAccount"
        @cancelForm="cancelForm"
        id="add-functionary-account"
        :headerTitle="$t('FUNCTIONARY.ADD_ACCOUNT_HEADER')"
        :selectedInstitution="selectedInstitution"
    >
        <template #buttons="{ submit, cancel }">
            <div class="col-9 text-right px-0">
                <st-button
                    :disabled="isLoading"
                    :callback="submit"
                    :spinner="isLoading"
                    class="mr-5"
                >
                    {{ $t("FORM_FIELDS.INVITE_BTN") }}
                </st-button>

                <st-button
                    variant="link"
                    :disabled="isLoading"
                    :callback="cancel"
                    :spinner="isLoading"
                >
                    {{ $t("FORM_FIELDS.CANCEL_BTN") }}
                </st-button>
            </div>
        </template>
    </functionary-account-form>
</template>

<script>
import FunctionaryAccountForm from "@/modules/administrator/components/users/FunctionaryAccountForm.vue";
import { mapActions, mapState } from "vuex";
import Message from '@/shared/message/message';

export default {
    name: "AddFunctionaryAccount",
    components: { FunctionaryAccountForm },
    props: {
        user_type_id: {
            type: String,
            required: true,
        },
        selectedInstitution: {
            type: Object,
            required: true
        }
    },
    methods: {
        async addAccount(account) {
            try {
                // staff user type id
                account.user_type_id = this.user_type_id;

                // in case the value is missing, default it to false
                account.is_organisation_admin = account.is_organisation_admin ?? false;

                const user = await this.addFunctionaryAccount(account);

                if (user) {
                    //create links between user and organisation/department/function
                    let multipleAssoc = [
                        {
                            action: "add",
                            parent_id: account.organisation_id,
                            parent_type: "organisation",
                            child_id: user.user_id,
                            child_type: "user",
                            comment: `Added user ${account.first_name} ${account.last_name} to organisation`,
                        }
                    ];

                    if (account.department_id) {
                        multipleAssoc.push(
                            {
                                action: "add",
                                parent_id: account.department_id,
                                parent_type: "department",
                                child_id: user.user_id,
                                child_type: "user",
                                comment: `Added user ${account.first_name} ${account.last_name} to department`,
                            }
                        );
                    }

                    if (account.function_id) {
                        multipleAssoc.push({
                            action: "add",
                            parent_id: account.function_id,
                            parent_type: "function",
                            child_id: user.user_id,
                            child_type: "user",
                            comment: `Added user ${account.first_name} ${account.last_name} to function`,
                        });
                    }

                    await this.addFunctionaryAssociations(multipleAssoc);

                    Message.success('FORM_FIELDS.MESSAGES.ACCOUNT_CREATED');

                    this.cancelForm();
                } else {
                    Message.error('FORM_FIELDS.MESSAGES.ACCOUNT_NOT_CREATED');
                }
            } catch (error) {
                Message.error('FORM_FIELDS.MESSAGES.ACCOUNT_NOT_CREATED');
            }
        },
        cancelForm() {
            this.$emit('cancelForm', false)
        },
        ...mapActions({
            addFunctionaryAccount: "administrator/form/addFunctionaryAccount",
            fetchUserTypes: "shared/getUserTypes",
            addFunctionaryAssociations: "administrator/form/addFunctionaryAssociations",
        }),
    },
    computed: {
        ...mapState({
            loading: (state) => state.shared.loading,
        }),
        isLoading() {
            return this.loading["administrator/addFunctionaryAccount"] || this.loading["administrator/addFunctionaryAssociations"];
        },
    },
};
</script>
