<template>
    <st-filters-pagination
        :header="$t('FUNCTIONARY.LIST.TITLE')"
        :total="total"
        stateModule="administrator/list"
        :filters="filters"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
        ref="filters"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
            </slot>
        </template>
        <slot>{{total}}</slot>
    </st-filters-pagination>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { FunctionaryModel } from '@/modules/administrator/models/functionary-model';

const { fields } = FunctionaryModel;

const filterSchema = new FilterSchema([
    fields.last_name,
    fields.email,
    fields.selectedDepartment,
    fields.selectedFunction,
    fields.status,
]);

export default {
    name: 'FunctionaryListFilter',
    props: {
        user_type_id: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            fields,
        }
    },
    computed: {
        ...mapGetters({
            total: 'administrator/list/total',
            accounts: 'administrator/list/rows',
            orgId: 'administrator/orgList/selectedOrganisationId',
        }),
        filters() {
            return filterSchema.fields;
        }
    },
    created() {
        const depField  = this.fields.selectedDepartment;
        depField.parentFields.forEach((child) => {
            depField.fetchParams = { [child.key] : this.orgId };
        });
    },
    methods: {
        ...mapActions({
            fetchFunctionaryAccounts: 'administrator/list/fetchFunctionaryAccounts',
            doFetchFunctionaryAccounts: 'administrator/list/doFetchFunctionaryAccounts',
        }),
        refresh() {
            this.doFilter(this.params);
        },
        doFilter(params) {
            this.params = params;
            let query = `user_type_id in [${this.user_type_id.join()}] and organisation_id eq '${this.$route.query.id}'`;
            this.doFetchFunctionaryAccounts({payload: params, query});
        },
    },
}
</script>
