<template>
    <st-page
        layout="tab"
        :showHeader="false"
    >
        <responsibility-list-filter :selectedInstitution="selectedInstitution" ref="listResponsibilitiesFilter">
            <template #toolbar>
                <st-button
                    v-can:create="'organisationsResponsibilities'"
                    variant="primary"
                    :callback="addResponsibility"
                >
                    <i class="fa fa-plus"></i>
                    {{ $t('ORG_RESPONSIBILITIES.BUTTONS.ADD_RESPONSIBILITY') }}
                </st-button>
            </template>
            <responsibility-list-table
                :selectedInstitution="selectedInstitution"
                @editResponsibility="editResponsibility"
                @updateResponsibility="updateResponsibility"
            />
        </responsibility-list-filter>

         <responsibility-modal
            ref="manage-responsibility"
            @updateResponsibility="updateResponsibility"
            :selectedInstitution="selectedInstitution"
            :selectedResponsibility="selectedResponsibility"
            :edit-mode="editMode"
        />
    </st-page>
</template>

<script>
    import ResponsibilityListFilter from '../components/responsibilities/ResponsibilityListFilter';
    import ResponsibilityListTable from '../components/responsibilities/ResponsibilityListTable';
    import ResponsibilityModal from '@/modules/administrator/components/responsibilities/ResponsibilityModal.vue';
    export default {
        name: 'ResponsibilityList',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        components: {
            ResponsibilityListFilter,
            ResponsibilityListTable,
            ResponsibilityModal
        },
        data() {
            return {
                selectedResponsibility: {},
                editMode: false,
            };
        },
        methods: {
            showModal() {
                this.$refs['manage-responsibility'].show();
            },
            addResponsibility() {
                this.editMode = false;
                this.selectedResponsibility= {};
                this.showModal();
            },
            updateResponsibility() {
                this.$refs.listResponsibilitiesFilter.refresh();
            },
            editResponsibility(data) {
                this.editMode = true;
                this.selectedResponsibility = data.item;
                this.showModal();
            }
        }
    }
</script>
