import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import { ResponsibilityField } from '@/modules/administrator/fields/responsibility-field';

const field = (name) => i18n.t(`ORG_RESPONSIBILITIES.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`ORG_RESPONSIBILITIES.PLACEHOLDERS.${name.toUpperCase()}`);


const fields = {
    responsibility_name: new StringField(
        'responsibility_name',
        field('responsibility_name'),
        { required: true },
        { placeholder: placeholder('responsibility_name')},
    ),
    responsibilities: ResponsibilityField.relationToOne(
        'responsibilities',
        field('responsibilities'),
        { required: true },
        { placeholder: placeholder('responsibilities') },
    ),
};

export class ResponsibilityModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
