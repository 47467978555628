<template>
    <div>
        <st-modal
            id="st-manage-accounts"
            hide-header-delimiter
            hide-footer-delimiter
            hideable
            size="md"
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                    {{ headerText}}
                </p>
            </template>
            <template #body>
                <manage-account-form
                    ref="manage-account-form"
                    :selectedAccount="selectedAccount"
                    :editMode="editMode"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </manage-account-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageAccount"
                    >
                        <span>{{ buttonText }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ManageAccountForm from './ManageAccountForm.vue';
export default {
    name: 'ManageAccount',
    components: {
        ManageAccountForm
    },
    props: {
        selectedAccount: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headerText() {
            return this.editMode ?
                this.$t('COLLECTOR_ACCOUNTS.MANAGE_ACCOUNT_MODAL.HEADER.EDIT') :
                this.$t('COLLECTOR_ACCOUNTS.MANAGE_ACCOUNT_MODAL.HEADER.ADD');
        },
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.UPDATE') : this.$t('GENERAL.BUTTON.ADD');
        },
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manageAccount() {
           this.$refs['manage-account-form'].manageAccount();
        },
        submit() {
            this.$emit('updateAccount');
        }
    }
};
</script>
