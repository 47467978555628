<template>
    <div>
        <st-modal
            id="st-modal-department"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <manage-department-form
                    ref="manage-department-form"
                    :selectedInstitution="selectedInstitution"
                    :selectedDepartment="selectedDepartment"
                    :editMode="editMode"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </manage-department-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageDepartment"
                    >
                        <span>{{buttonText}}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ManageDepartmentForm from './ManageDepartmentForm.vue';
export default {
    name: 'ManageDepartmentModal',
    components: {
        ManageDepartmentForm
    },
    props: {
        selectedDepartment: {
            type: Object,
            required: true
        },
        selectedInstitution: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.UPDATE') : this.$t('GENERAL.BUTTON.ADD');
        },
        headerText() {
            return this.editMode ?
                this.$t('DEPARTMENTS.ADD_EDIT_MODAL.HEADER.EDIT') :
                this.$t('DEPARTMENTS.ADD_EDIT_MODAL.HEADER.ADD');
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manageDepartment() {
            this.$refs['manage-department-form'].manageDepartment();
        },
        submit() {
            this.$emit('updateDepartment');
        }
    }
};
</script>

