<template>
    <div>
        <associated-responsibilities-modal
            ref="assocRespModal"
            @updateResponsibilities="updateResponsibilities"
            :selectedFunction="selectedFunction"
            :selectedInstitution="selectedInstitution"
        />

        <users-modal
            ref="usersAccountsModal"
            :entity="selectedEntity"
            :institution="selectedInstitution"
        />

        <st-data-table
            :items="functions"
            :fields="fields"
            :actions="actions"
            :loading="isLoading"
            :modelPresenter="presenter"
            @edit="editFunction"
            @delete="deleteFunction"
            @users="showUsers"
            @responsability="showResponsability"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import UsersModal from '@/modules/administrator/components/modals/UsersModal.vue';
    import AssociatedResponsibilitiesModal from '@/modules/administrator/components/functions/AssociatedResponsibilitiesModal.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { FunctionModel } from '@/modules/administrator/models/function-model';
    const { fields } = FunctionModel;
    export default {
        name: 'FunctionListTable',
        components: {
            UsersModal,
            AssociatedResponsibilitiesModal,
        },
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                presenter: FunctionModel.presenter,
                selectedEntity: {},
                selectedFunction: {},
            };
        },
        computed: {
            ...mapGetters({
                rows: 'administrator/funcList/rows',
                loading: 'shared/loading',
                orgPermissions: 'administrator/orgList/orgPermissions',
            }),
            isLoading () {
                return this.loading['admin/getFunctions'] ;
            },
            fields() {
                return [
                    fields.name,
                    fields.description,
                ]
            },
            actions() {
                const actions = [];
                if (this.orgPermissions.hasPermissionToEditFunctions) {
                    actions.push({
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('INSTITUTION.MAIN_PAGE.TOOLTIPS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        hideOnRow: true,
                    })
                }
                if (this.orgPermissions.hasPermissionToViewUsers) {
                    actions.push({
                        name: 'users',
                        icon: 'users',
                        tooltipText: this.$t('INSTITUTION.FUNCTIONS.TOOLTIPS.USERS'),
                        customIcon: false,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToViewResponsibilities) {
                    actions.push({
                        name: 'responsability',
                        icon: '<i class="far fa-id-card"></i>',
                        tooltipText: this.$t('INSTITUTION.FUNCTIONS.TOOLTIPS.RESPOSABILITIES'),
                        customIcon: true,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToDestroyFunctions) {
                    actions.push({
                        name: 'delete',
                        tooltipText: this.$t('INSTITUTION.FUNCTIONS.TOOLTIPS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        type: 'danger',
                        hideOnRow: true,
                    })
                }

                return actions
            },
            functions() {
                return this.rows.map((row) => {
                    let hideAction =  true;
                    if (!row.system_default) hideAction = false;

                    return {...row, hideAction }
                });
            },
        },
        methods: {
            ...mapActions({
                removeFunction: 'administrator/funcList/deleteFunction'
            }),
            editFunction(data) {
                this.$emit('editFunction', data);
            },
            deleteFunction(data) {
                this.removeFunction(data.item.id)
                .then(() => {
                    this.$emit('updateFunction');
                    this.$notify({
                        type: 'success',
                        message: this.$t('FUNCTIONS.SUCCESS.DELETE'),
                    });
                })
            },
            showUsers(data) {
                this.selectedEntity = {type: 'function', data: data.item};
                this.$refs.usersAccountsModal.show();
            },
            showResponsability(data) {
                this.selectedFunction = data.item;
                this.$refs.assocRespModal.show();
            },
            updateResponsibilities() {
                this.$emit('updateFunction');
            }
        },
    };
</script>
