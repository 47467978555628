<template>
    <div>
        <Loader v-if="isLoading"/>

        <st-page layout="tab" :showHeader="false">
            <document-series-filters
                :total="total"
                @params="doFilter"
            >
                <template #toolbar>
                    <st-button
                        v-can:create="'organisationsDocumentSeries'"
                        :callback="openAddModal"
                    >
                        <i class="fa fa-plus"></i>
                        {{ $t('DOCUMENT_SERIES.BUTTONS.ADD_SERIES') }}
                    </st-button>
                </template>

                <document-series-table
                    @edit="openEditModal"
                />
            </document-series-filters>
        </st-page>

        <add-document-series-modal
            ref="add-document-series-modal"
            @submit="submitAddDocumentSeries"
            :loading="isLoadingAddButton"
        />

        <edit-document-series-modal
            ref="edit-document-series-modal"
            :selected-item="selectedItem"
            @submit="submitEditDocumentSeries"
            :loading="isLoadingEditButton"
        />

    </div>
</template>

<script>
import DocumentSeriesFilters from './DocumentSeriesFilters.vue';
import DocumentSeriesTable from './DocumentSeriesTable.vue';
import AddDocumentSeriesModal from './AddDocumentSeriesModal.vue';
import EditDocumentSeriesModal from './EditDocumentSeriesModal.vue';

import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';

export default {
    name: 'DocumentSeries',
    components: {
        DocumentSeriesFilters,
        DocumentSeriesTable,
        AddDocumentSeriesModal,
        EditDocumentSeriesModal,
    },
    data() {
        return {
            params: {},
            selectedItem: {},
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            total: 'administrator/documentSeries/total',
        }),
        isLoading() {
            return this.loading['administrator/documentSeries/fetchDocumentSeries'];
        },
        isLoadingAddButton() {
            return !!this.loading['administrator/documentSeries/addDocumentSeries'];
        },
        isLoadingEditButton() {
            return !!this.loading['administrator/documentSeries/editDocumentSeries'];
        },
    },
    methods: {
        ...mapActions({
            doFetch: 'administrator/documentSeries/fetchDocumentSeries',
            addDocumentSeries: 'administrator/documentSeries/addDocumentSeries',
            editDocumentSeries: 'administrator/documentSeries/editDocumentSeries',
        }),
        refresh() {
            this.doFetch(this.params);
        },
        doFilter(params) {
            this.params = params;
            this.refresh();
        },
        openAddModal() {
            this.$refs['add-document-series-modal'].show();
        },
        openEditModal(item) {
            this.selectedItem = item;
            this.$refs['edit-document-series-modal'].show();
        },
        async submitAddDocumentSeries(series) {
            await this.addDocumentSeries(series);
            this.$refs['add-document-series-modal'].hide();
            Message.success('DOCUMENT_SERIES.NOTIFIERS.SAVED');
            this.refresh();
        },
        async submitEditDocumentSeries(series) {
            await this.editDocumentSeries({ id: this.selectedItem.id, payload: series });
            this.$refs['edit-document-series-modal'].hide();
            Message.success('DOCUMENT_SERIES.NOTIFIERS.EDITED');
            this.refresh();
        },
    },
}
</script>
