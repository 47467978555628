<template>
    <div>
        <st-modal
            :id="modalId"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="headerText"
            customClass="form-modal"
            ref="modal"
        >
            <template #body>
                <ManageUrbanismRegulationZonesForm
                    ref="urbanism-regulation-zones-form"
                    :editMode="editMode"
                    @cancelForm="hide"
                    @submit="update"
                >
                </ManageUrbanismRegulationZonesForm>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';
import ManageUrbanismRegulationZonesForm from './ManageUrbanismRegulationZonesForm.vue';

export default {
    name: 'ManageUrbanismRegulationZonesModal',
    components: { ManageUrbanismRegulationZonesForm },
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modalId: `st-modal-urbanism-regulation-zones-${generateRandomId()}`,
        }
    },
    computed: {
        headerText() {
            return this.editMode ?
                this.$t('URBANISM_REGULATION_ZONES.FORM.EDIT_TITLE') :
                this.$t('URBANISM_REGULATION_ZONES.FORM.ADD_TITLE');
        },
    },
    methods: {
        ...mapActions({
            unselectRecord: 'administrator/urbanismRegulationZonesForm/unselect',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            if(this.editMode) this.unselectRecord();
            this.$refs['modal'].hide();
        },
        update() {
            this.$emit('update');
        }
    },
};
</script>

