<template>
    <st-page
        layout="tab"
        :showHeader="false"
    >
        <department-list-filter :selectedInstitution="selectedInstitution" ref="listDepFilter">
            <template #toolbar>
                <st-button
                    v-can:create="'organisationsDepartments'"
                    variant="primary"
                    :callback="addDepartment"
                >
                    <i class="fa fa-plus"></i>
                    {{ $t('DEPARTMENTS.BUTTON.ADD_DEPARTMENT') }}
                </st-button>
            </template>
            <department-list-table :selectedInstitution="selectedInstitution" @editDepartment="editDepartment"/>
        </department-list-filter>

         <manage-department
            ref="manage-department"
            @updateDepartment="updateDepartment"
            :selected-department="selectedDepartment"
            :selectedInstitution="selectedInstitution"
            :edit-mode="editMode"
        />
    </st-page>
</template>

<script>
    import DepartmentListFilter from '../components/departments/DepartmentListFilter';
    import DepartmentListTable from '../components/departments/DepartmentListTable';
    import ManageDepartment from '@/modules/administrator/components/departments/ManageDepartmentModal.vue';
    export default {
        name: 'DepartmentList',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        components: {
            DepartmentListFilter,
            DepartmentListTable,
            ManageDepartment
        },
        data() {
            return {
                showManageDepartment: false,
                selectedDepartment: {},
                editMode: false,
            };
        },
        methods: {
            showModal() {
                this.$refs['manage-department'].show();
            },
            addDepartment() {
                this.editMode = false;
                this.showModal();
            },
            updateDepartment() {
                this.$refs.listDepFilter.refresh();
            },
            editDepartment(data) {
                this.editMode = true;
                this.selectedDepartment = data.item;
                this.showModal();
            }
        }
    }
</script>
