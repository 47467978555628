<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :loading="isLoading"
            :modelPresenter="presenter"
            @edit="editResponsibility"
            @delete="deleteResponsibility"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { ResponsibilityModel } from '@/modules/administrator/models/responsibility-model';

    const { fields } = ResponsibilityModel;

    export default {
        name: 'ResponsibilityListTable',
        data() {
            return {
                presenter: ResponsibilityModel.presenter,
            };
        },
        computed: {
            ...mapState({
                loading: state => state.shared.loading,
            }),
            ...mapGetters({
                rows: 'administrator/respList/rows',
                orgPermissions: 'administrator/orgList/orgPermissions',
            }),
            isLoading () {
                return this.loading['admin/getOrgResponsibilities'] ;
            },
            fields() {
                return [
                    fields.responsibility_name,
                ]
            },
            actions() {
                const actions = [];
                if (this.orgPermissions.hasPermissionToEditResponsibilities) {
                    actions.push({
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    })
                }

                if (this.orgPermissions.hasPermissionToDestroyResponsibilities) {
                    actions.push({
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        disabled: true,
                        type: 'danger'
                    })
                }

                return actions
            }
        },
        methods: {
            ...mapActions({
                removeResponsibility: 'administrator/respList/removeResponsibility'
            }),
            deleteResponsibility(data) {
                // TODO: uncomment/enable after BE is done
                // this.removeResponsibility(data.item.id)
                // .then(() => {
                //     this.$emit('updateResponsibility');
                // })
            },
            editResponsibility(data) {
                this.$emit('editResponsibility', data);
            },
        },
    };
</script>
