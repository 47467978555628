<template>
    <st-modal
        ref='st-add-document-series-modal'
        hideable
        hide-footer
        hide-header-delimiter
        hide-footer-delimiter
        no-close-on-backdrop
        no-close-on-esc
        size="md"
        :hideHeaderClose="loading"
        :title="$t('DOCUMENT_SERIES.MODALS.ADD_TITLE')"
    >
        <template #body>
            <document-series-form
                @cancel="hide"
                @submit="submit"
            >
                <template #buttons="{ submit, cancel }">
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="cancel"
                            :disabled="loading"
                        >
                            {{ $t('DOCUMENT_SERIES.MODALS.CANCEL') }}
                        </st-button>
                        <st-button
                            size="large"
                            :callback="submit"
                            :spinner="loading"
                        >
                            {{ $t('DOCUMENT_SERIES.MODALS.ADD') }}
                        </st-button>
                    </div>
                </template>
            </document-series-form>
        </template>
    </st-modal>
</template>

<script>
import DocumentSeriesForm from './DocumentSeriesForm.vue';

export default {
    name: 'AddDocumentSeriesModal',
    components: {
        DocumentSeriesForm,
    },
    props: {
        loading: {
            type: Boolean,
        },
    },
    methods: {
        show() {
            this.$refs['st-add-document-series-modal'].show();
        },
        hide() {
            this.$refs['st-add-document-series-modal'].hide();
        },
        submit(series) {
            this.$emit('submit', series);
        },
    },
}
</script>