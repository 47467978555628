<template>
    <st-data-table
        :items="territorialUnits"
        :fields="fields"
        :actions="actions"
        :modelPresenter="presenter"
        :loading="loading['admin/fetchTerritorialUnitAllocation']"
        hover
        @delete="onDelete"
        :emptyText="$t('TERRITORIAL_UNIT_ALLOCATION.TABLE_EMPTY_TEXT')"
    >
    </st-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TerritorialUnitAllocationModel } from '@/modules/administrator/models/territorial-unit-allocation-model';

const { fields } = TerritorialUnitAllocationModel;

export default {
    name: 'TerritorialUnitAllocationListTable',
    data() {
        return {
            presenter: TerritorialUnitAllocationModel.presenter,
            fields: [
                fields.tu_name,
            ],
        }
    },
    computed: {
        ...mapGetters({
            territorialUnits: 'administrator/territorialUnitAllocation/rows',
            orgPermissions: 'administrator/orgList/orgPermissions',
            loading: 'shared/loading',
        }),
        actions() {
            const actions = [];
            if (this.orgPermissions.hasPermissionToDestroyTerritorialUnitAllocation) {
                actions.push({
                    name: 'delete',
                    tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                    icon: 'trash-alt',
                    customIcon: false,
                    type: 'danger',
                })
            }
            return actions;
        }
    },
    methods: {
        ...mapActions({
            deleteUAT: 'administrator/territorialUnitAllocationForm/removeUatToStaff',
        }),
        async onDelete(uat) {
            await this.deleteUAT(uat.item.id);
            this.$emit('UatUpdated');
        },
    },
}
</script>
