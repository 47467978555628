import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';

export class ResponsibilityField {
    static relationToOne(name, label, filterParams) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve, reject) => {
                    ApiService.query('/responsibilities', { params: { limit: 100 }})
                    .then(({data}) => {
                        resolve(data.data)
                    })
                    .catch(( error ) => {
                        Errors.handle(error);
                        reject();
                    })
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            filterParams,
        );
    }
}
