<template>
    <div>
        <Loader v-if="isLoading"/>

        <st-page layout="tab" :showHeader="false">
            <taxes-filters
                :total="total"
                @params="doFilter"
            >
                <template #toolbar>
                    <st-button
                        v-can:create="'organisationsTaxes'"
                        :callback="openAddTaxModal"
                    >
                        <i class="fa fa-plus"></i>
                        {{ $t('TAXES.BUTTONS.ADD_TAX') }}
                    </st-button>
                </template>

                <taxes-table
                    @edit="openEditTaxModal"
                />
            </taxes-filters>
        </st-page>


        <add-tax-modal
            ref='add-tax-modal'
            @submit="submitAddTax"
            :loading="isLoadingAddButton"
        />

        <edit-tax-modal
            ref='edit-tax-modal'
            :selected-item="selectedItem"
            @submit="submitEditTax"
            :loading="isLoadingEditButton"
        />
    </div>
</template>

<script>
import TaxesFilters from './TaxesFilters.vue';
import TaxesTable from './TaxesTable.vue';
import AddTaxModal from './AddTaxModal.vue';
import EditTaxModal from './EditTaxModal.vue';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';

export default {
    name: 'Taxes',
    data() {
        return {
            params: {},
            selectedItem: {},
        }
    },
    components: {
        TaxesFilters,
        TaxesTable,
        AddTaxModal,
        EditTaxModal,
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            total: 'administrator/totalTaxes',
        }),
        isLoading() {
            return this.loading['administrator/fetchTaxes'];
        },
        isLoadingAddButton() {
            return !!this.loading['administrator/addTax'];
        },
        isLoadingEditButton() {
            return !!this.loading['administrator/updateTax'];
        },
    },
    methods: {
        ...mapActions({
            fetchTaxes: 'administrator/fetchTaxes',
            addTax: 'administrator/addTax',
            updateTax: 'administrator/updateTax',
        }),
        refresh() {
            this.fetchTaxes(this.params);
        },
        doFilter(params) {
            this.params = params;
            this.refresh();
        },
        openAddTaxModal() {
            this.$refs['add-tax-modal'].show();
        },
        openEditTaxModal(item) {
            this.selectedItem = item;
            this.$refs['edit-tax-modal'].show();
        },
        async submitAddTax(tax) {
            await this.addTax(tax);
            this.$refs['add-tax-modal'].hide();
            Message.success('TAXES.NOTIFIERS.SAVED');
            this.refresh();
        },
        async submitEditTax(tax) {
            await this.updateTax({ id: this.selectedItem.id, payload: tax })
            this.$refs['edit-tax-modal'].hide();
            Message.success('TAXES.NOTIFIERS.EDITED');
            this.refresh();
        },
    },
}
</script>
