<template>
    <st-filters-pagination
        stateModule="administrator/respList"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :header="$t('ORG_RESPONSIBILITIES.LIST.TITLE')"
        :showErase="false"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
            </slot>
        </template>

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ResponsibilityModel } from '@/modules/administrator/models/responsibility-model';

    const { fields } = ResponsibilityModel;

    const filterSchema = new FilterSchema([
        fields.responsibility_name,
    ]);

    export default {
        name: 'ResponsibilityListFilter',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters({
               total: 'administrator/respList/total',
            }),
            filters() {
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'administrator/respList/getOrgResponsibilities',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch({payload: params, id: this.selectedInstitution.id});
            },
        },

    };
</script>
