<template>
    <st-filters-pagination
        :header="$t('DOCUMENT_SERIES.LIST.HEADER')"
        :total="total"
        :filters="filters"
        :moreFilters="false"
        :currentPage="currentPage"
        :showErase="false"
        @currentPage="currentPage = $event"
        @change="onChange"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
            </slot>
        </template>

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { DocumentSeriesModel } from './models/document-series-model';
import { FilterSchema } from '@/shared/form/filter-schema';

const { fields } = DocumentSeriesModel;

const filterSchema = new FilterSchema([
    fields.name,
]);

export default {
    name: 'DocumentSeriesFilters',
    props: {
        total: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            filters: filterSchema.fields,
            currentPage: 1,
        }
    },
    methods: {
        onChange(params) {
            this.$emit('params', params);
        },
    },
}
</script>