<template>
    <div>
        <associated-functions-modal
            ref="associatedFunctionsModal"
            :department="selectedDepartment"
            :institution="selectedInstitution"
        />

        <users-modal
            ref="usersAccountsModal"
            :entity="selectedEntity"
            :institution="selectedInstitution"
        />

        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :loading="isLoading"
            :modelPresenter="presenter"
            @edit="editDepartment"
            @functions="showAssociatedFunctions"
            @users="showUsers"
            @delete="deleteDepartment"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import AssociatedFunctionsModal from '@/modules/administrator/components/departments/AssociatedFunctionsModal.vue';
    import UsersModal from '@/modules/administrator/components/modals/UsersModal.vue';
    import { mapState, mapGetters } from 'vuex';
    import { DepartmentModel } from '@/modules/administrator/models/department-model';

    const { fields } = DepartmentModel;

    export default {
        name: 'DepartmentListTable',
        components: {
            UsersModal,
            AssociatedFunctionsModal,
        },
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                presenter: DepartmentModel.presenter,
                selectedEntity: {},
                selectedDepartment: {},
            };
        },
        computed: {
            ...mapState({
                loading: state => state.shared.loading,
            }),
            ...mapGetters({
                rows: 'administrator/depList/rows',
                orgPermissions: 'administrator/orgList/orgPermissions',
            }),
            isLoading () {
                return this.loading['admin/getDepartments'] ;
            },
            fields() {
                return [
                    fields.name,
                    {
                        ...fields.status,
                        labelType: true,
                    },
                ]
            },
            actions() {
                const actions = [];
                if (this.orgPermissions.hasPermissionToEditDepartments) {
                    actions.push({
                         name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToViewDepartmentsFunc) {
                    actions.push({
                        name: 'functions',
                        icon: '<i class="fas fa-id-badge"></i>',
                        tooltipText: this.$t('DEPARTMENTS.TOOLTIPS.FUNCTIONS'),
                        customIcon: true,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToViewUsers) {
                    actions.push({
                        name: 'users',
                        icon: '<i class="fas fa-users"></i>',
                        tooltipText: this.$t('DEPARTMENTS.TOOLTIPS.USERS'),
                        customIcon: true,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToDestroyDepartments) {
                    actions.push({
                        name: 'delete',
                        tooltipText: this.$t('INSTITUTION.FUNCTIONS.TOOLTIPS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        disabled: true,
                        type: 'danger'
                    })
                }

                return actions
            }
        },
        methods: {
            // TODO: use delete department once BE is done, util then, delete is disabled
            deleteDepartment(data) {
            },
            editDepartment(data) {
                this.$emit('editDepartment', data);
            },
            showUsers (data) {
                this.selectedEntity = {type: 'department', data: data.item};
                this.$refs.usersAccountsModal.show();

            },
            showAssociatedFunctions(data) {
                this.selectedDepartment = data.item;
                this.$nextTick(() => {
                    this.$refs.associatedFunctionsModal.show();
                });
            },
        },
    };
</script>
