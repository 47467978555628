<template>
    <div>
        <Loader v-if="isLoading"/>
        <form :id="id" class="pt-10 pb-10">
            <div class="card-body pt-0">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="col-3 text-left px-0">
                        <p class="st-dark-heading mb-0">
                            {{ headerTitle }}
                        </p>
                    </div>
                    <slot name="buttons" :submit="submit" :cancel="cancel"></slot>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.LAST_NAME") }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="account.last_name"
                                    ref="last_name"
                                    name="last_name"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.FIRST_NAME") }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="account.first_name"
                                    ref="first_name"
                                    name="first_name"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.EMAIL") }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="account.user_email"
                                    ref="email"
                                    name="email"
                                    :disabled="account.status === 'active'"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.IS_ORGANISATION_ADMIN") }}
                            </label>
                            <div>
                                <b-form-select
                                    :options="isAdminOptions"
                                    v-model="selectedIsAdmin"
                                    value-field="value"
                                    text-field="text"
                                    ref="is_admin"
                                    name="is_admin"
                                >
                                </b-form-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.PHONE") }} *
                            </label>
                            <st-input-text
                                v-model="account.phone_number"
                                ref="phone_number"
                                name="phone_number"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.TAG_ID") }} *
                            </label>
                            <st-input-text
                                v-model="account.identification_number"
                                ref="identification_number"
                                name="identification_number"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.TAG_RELEASED_BY") }} *
                            </label>
                            <st-input-text
                                v-model="account.identification_issued_by"
                                ref="identification_issued_by"
                                name="identification_issued_by"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.EXTERNAL_ENROLLMENT_ID") }}
                            </label>
                            <st-input-text
                                v-model="account.external_enrollment_id"
                                ref="extrenal_enrollment_id"
                                name="extrenal_enrollment_id"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.ORGANISATION") }} *
                            </label>
                            <st-input-text
                                    v-model="account.organisation_name"
                                    ref="organisation"
                                    name="organisation"
                                    disabled
                                />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.DEPARTMENT") }}
                            </label>
                            <b-form-select
                                :options="departments"
                                v-model="selectedDepartment"
                                value-field="id"
                                text-field="name"
                                ref="department"
                                name="department"
                                v-on:change="loadDepartmentFunctions"
                                :disabled="disableFields"
                            >
                                <template #first>
                                    <b-form-select-option :value="null">
                                        {{ $t('GENERAL.SELECT_PLACEHOLDER')}}
                                    </b-form-select-option>
                                </template>
                            </b-form-select>

                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t("FORM_FIELDS.FUNCTION") }}
                            </label>
                            <b-form-select
                                :options="functions"
                                v-model="selectedFunction"
                                value-field="function_id"
                                text-field="function_name"
                                ref="function"
                                name="function"
                                v-on:change="loadFunctionResponsibilities"
                                :disabled="disableFields"
                            >
                            </b-form-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body pt-0" v-if="selectedAccount.user_email">
                <div class="row">
                    <div class="col-4">
                        <label>
                            {{ $t("FORM_FIELDS.RESPONSIBILITIES") }}
                        </label>
                        <ul>
                            <li
                                v-for="responsibility in responsibilities"
                                :key="responsibility.id"
                            >
                                {{ responsibility }}
                            </li>
                        </ul>
                    </div>

                    <div class="col-4">
                        <label>
                            {{ $t("FORM_FIELDS.ASSIGNED_TERRITORIAL_ADMINISTRATIVE_UNIT") }}
                        </label>
                        <ul>
                            <li
                                v-for="unit in territorialUnits"
                                :key="unit.id"
                            >
                                {{ unit.tu_name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Roles from '@/security/roles';

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { mapActions, mapGetters } from "vuex";

//TO DO - use formSchema

export default {
    name: "FunctionaryAccountForm",
    props: {
        selectedAccount: {
            type: Object,
            default() {
                return {
                    user_email: "",
                    last_name: "",
                    first_name: "",
                    phone_number: "",
                    identification_number: "",
                    identification_issued_by: "",
                    external_enrollment_id: "",
                    organisation_id: this.$route.query.id,
                    organisation_name: "",
                    departments: [],
                    functions: [],
                    responsibilities: [],
                    status: "pending",
                };
            },
        },
        id: {
            type: String,
            required: true,
        },
        headerTitle: {
            type: String,
            required: true,
        },
        selectedInstitution: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            account: { ...this.selectedAccount },
            departments: [],
            selectedDepartment: "",
            selectedFunction: "",
            selectedIsAdmin: this.selectedAccount?.is_organisation_admin || false,
            functions: [],
            isAdminOptions: [
                {
                    value: true,
                    text: this.$t('GENERAL.YES'),
                },
                {
                    value: false,
                    text: this.$t('GENERAL.NO')
                }
            ],
            responsibilities: [],
            initialAccount: this.account,
            disableFields: false
        };
    },
    methods: {
        ...mapActions({
            getOrganisationDepartments: "administrator/getOrgDepartments",
            getDepartmentFunctions: "administrator/getDepartmentFunctions",
            getFunctionResponsibilities: "administrator/form/getFunctionResponsibilities",
            getTerritorialUnitAllocation: 'administrator/territorialUnitAllocation/fetchTerritorialUnitAllocation',
        }),
        submit() {
            this.fv.validate().then((status) => {
                if (status == "Valid") {
                    this.account.department_id = this.selectedDepartment;
                    this.account.function_id = this.selectedFunction ??  "";
                    this.account.responsibilities = this.responsibilities;

                    this.account.departments = this.departments
                        .filter((item) => item.id === this.selectedDepartment)
                        .map((el) => el.name);
                    this.account.functions = this.functions
                        .filter((item) => item.function_id === this.selectedFunction)
                        .map((el) => el.function_name);
                    this.account.responsibilities = this.responsibilities;
                    this.account.is_organisation_admin = this.selectedIsAdmin;

                    this.$emit("submitForm", this.account);
                }
            });
        },
        cancel() {
            this.$emit("cancelForm");
        },
        loadDepartmentFunctions(departmentID) {
            this.getDepartmentFunctions(departmentID).then(({ data }) => {
                this.functions = data;
                this.responsibilities = this.account.organisation_id ? this.account.responsibilities : [];
                if (this.$route.query?.user && this.account.functions?.length) {
                    this.getSelectedFunction(this.account.functions);
                }
            });
        },
        loadFunctionResponsibilities(functionID) {
            if (!functionID) return [];
            this.getFunctionResponsibilities(functionID).then((data) => {
                this.responsibilities = data?.responsibilities ? data.responsibilities : [];
            });
        },
        getSelectedDepartment(name) {
            //reset department
            if (name) {
                this.selectedDepartment = "";
                if (this.departments.length !== 0) {
                    this.selectedDepartment = this.departments?.find((item) => name.includes(item.name))?.id;
                    this.account.initialDep = this.departments?.find((item) => name.includes(item.name));
                }
            }
        },
        getSelectedFunction(name) {
            if (name) {
                //reset function
                this.selectedFunction = "";

                if (this.functions.length !== 0) {
                    this.selectedFunction = this.functions?.find((item) => name.includes(item.function_name))?.function_id || '';
                    if (this.selectedFunction) {
                        this.account.initialFunc = this.functions?.find((item) => name.includes(item.function_name)) || {};
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters({
            territorialUnits: 'administrator/territorialUnitAllocation/rows',
            loading: 'shared/loading',
        }),
        departmentsLoading() {
            return this.loading["administrator/getDepartments"];
        },
        functionsLoading() {
            return this.loading["administrator/getDepartmentFunctions"];
        },
        responsibilitiesLoading() {
            return this.loading["administrator/getFunctionResponsibilities"];
        },
        isLoading() {
            return this.departmentsLoading || this.functionsLoading || this.responsibilitiesLoading;
        },
        isAdmin() {
            return this.selectedAccount?.user_type_name && this.selectedAccount?.user_type_name === Roles.values['admin'];
        },
        isStaff() {
            return this.selectedAccount?.user_type_name && this.selectedAccount?.user_type_name === Roles.values['staff'];
        }
    },
    created() {
        if (this.account.organisation_id) {
            this.account.organisation_name = this.selectedInstitution.name;
            this.getOrganisationDepartments(this.account.organisation_id).then((result) => {
                this.departments = result.data.filter(dep => dep.status === 'active');
                this.getSelectedDepartment(this.account.departments);
                if (this.selectedDepartment) {
                    this.loadDepartmentFunctions(this.selectedDepartment);
                }
            });
        }

        // Edit mode
        if (this.selectedAccount.user_email) {
            this.getTerritorialUnitAllocation({payload: {limit: 100}, id: this.selectedAccount.user_id})
        }
    },
    watch: {
        "account.organisation_id": {
            deep: true,
            immediate: false,
            handler(newOrg, oldOrg) {
                if (newOrg === oldOrg) return false;
                if (newOrg) {
                    this.departments = [];

                    this.getOrganisationDepartments(this.account.organisation_id).then((result) => {
                        this.departments = result.data;
                    });
                }
            },
        },
        selectedIsAdmin(value) {
            if (this.editMode) {
                if (this.isStaff) this.disableFields = value;
                if (this.isAdmin) this.disableFields = !value;
            }
        }
    },
    mounted() {
        const form = KTUtil.getById(this.id);

        //TO DO - replace this with createFormValidation method
        const notEmptyValidator = {
            message: this.$t("AUTH.VALIDATION.REQUIRED_FIELD"),
        };

        this.fv = formValidation(form, {
            fields: {
                email: {
                    validators: {
                        notEmpty: notEmptyValidator,
                        emailAddress: {
                            message: this.$t("AUTH.VALIDATION.INVALID_EMAIL"),
                        },
                    },
                },
                first_name: {
                    validators: {
                        notEmpty: notEmptyValidator,
                        regexp: {
                            regexp: /^([a-zA-Z]+)(([-'\s]{1})?[a-zA-Z]+)+?$/,
                            message: this.$t("AUTH.VALIDATION.ALPHABETICAL_SPECIAL_CHARS")
                        }
                    },
                },
                last_name: {
                    validators: {
                        notEmpty: notEmptyValidator,
                        regexp: {
                            regexp: /^([a-zA-Z]+)(([-'\s]{1})?[a-zA-Z]+)+?$/,
                            message: this.$t("AUTH.VALIDATION.ALPHABETICAL_SPECIAL_CHARS")
                        }
                    },
                },
                organisation: {
                    validators: {
                        notEmpty: notEmptyValidator,
                    },
                },
                phone_number: {
                    validators: {
                        notEmpty: notEmptyValidator,
                        phoneNumber: {
                            message: this.$t("AUTH.VALIDATION.INVALID_PHONE"),
                        },
                    },
                },
                identification_number: {
                    validators: {
                        notEmpty: notEmptyValidator,
                    },
                },
                identification_issued_by: {
                    validators: {
                        notEmpty: notEmptyValidator,
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        });
    },
};
</script>
<style lang="scss" scoped>
    .permissions-section {
        height: 200px;
        overflow: auto;
    }
</style>
