<template>
    <div>
        <st-filters-pagination
            stateModule="administrator/funcList"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :moreFilters="false"
            :header="$t('FUNCTIONS.LIST.TITLE')"
            :showErase="false"
        >
            <template #filters-toolbar>
                <slot name="toolbar">
                </slot>
            </template>

            <slot></slot>
        </st-filters-pagination>
        <p class="font-size-xs ml-4 py-4" v-if="systemFunctions">
            *{{ $t('FUNCTIONS.LIST.SYSTEM_FUNCTIONS', { systemFunctions }) }}
        </p>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { FunctionModel } from '@/modules/administrator/models/function-model';

    const { fields } = FunctionModel;

    const filterSchema = new FilterSchema([
        fields.name,
    ]);

    export default {
        name: 'FunctionListFilter',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                filters: filterSchema.fields,
                systemFunctions: []
            }
        },
        computed: {
            ...mapGetters({
               total: 'administrator/funcList/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'administrator/funcList/getFunctionsByOrg',
                fetchSystemFunctions: 'administrator/list/doFetchSystemFunctions',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch({payload: params, id: this.selectedInstitution.id});
            },
        },
        async created() {
            const sysFunctionList = await this.fetchSystemFunctions(this.$route.query.id);
            this.systemFunctions = sysFunctionList.map(func => func.name).join(', ');
        }
    };
</script>
