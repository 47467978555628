<template>
    <div>
        <st-modal
            id="st-associated-responsibilities-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hideable
            size="md"
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0 text-center">
                    {{ $t('FUNCTIONS.ASSOCIATED_RESPOSABILITIES_MODAL.HEADER')}}
                </p>
            </template>
                <template #body>
                    <Loader v-if="loading['admin/getResponsibilities']"/>
                    <div class="d-flex justify-content-start ml-10 mb-10">
                        <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                            {{ $t('FUNCTIONS.ASSOCIATED_RESPOSABILITIES_MODAL.BODY.TOP')}}: {{selectedFunction.name}}
                        </p>
                    </div>
                    <div class="associated-responsibilities-wrapper">
                        <b-form-group>
                            <b-form-checkbox
                                v-for="(option) in responsibilities"
                                v-model="selected"
                                :key="option.responsibility_id"
                                :value="option.responsibility_name"
                                stacked
                                size="lg"
                                >
                                {{ option.responsibility_name }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="confirm"
                    > {{ $t('GENERAL.BUTTON.CONFIRM') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters} from 'vuex';
export default {
    name: 'AssociatedResponsibilitiesModal',
    props: {
        selectedFunction: {
            type: Object,
            required: true
        },
        selectedInstitution: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selected: [],
        };
    },
    computed: {
        ...mapState({
            loading: state => state.shared.loading,
        }),
        ...mapGetters({
            responsibilities: 'administrator/responsibilities',
        }),
    },
    methods: {
        ...mapActions({
            getResponsibilities: 'administrator/getResponsibilities',
            addResponsibilitiesToFunction: 'administrator/addResponsibilitiesToFunction',
        }),
        hide() {
            this.selected = [];
            this.$refs['modal'].hide();
        },
        show() {
            this.getResponsibilities(this.selectedInstitution).then(() => {
                this.selected = this.selectedFunction.responsibilities;
            });
            this.$refs['modal'].show();
        },
        confirm() {
            const payload = {
                responsibilities: this.selected
            }
            this.addResponsibilitiesToFunction({functionId: this.selectedFunction.id, payload})
            .then(()=> {
                this.$emit('updateResponsibilities');
                this.hide();
            })
        }
    }
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/administrator/functions.scss"></style>
