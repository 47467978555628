<template>
    <div>
        <div class="row">
            <div class="col">{{ fields.start.label }}</div>
            <div class="col">{{ fields.end.label }}</div>
            <div class="col">{{ fields.value.label }}</div>

        </div>
        <div v-for="(range, index) of ranges" :key="index">
            <range 
                :range="range"
                :index="index"
                :readonly="isReadonly(index)"
                @change="rangeChanged"
            >
            </range>
        </div>
        <p v-if="!valid" class="text-danger">{{ $t('TAXES.NOTIFIERS.INVALID_RANGES') }}</p>
    </div>
</template>

<script>
import Range from './Range.vue';
import { FormSchema } from '@/shared/form/form-schema';
import { RangeModel } from './models/range-model';
const { fields } = RangeModel;

const formSchema = new FormSchema([
    fields.start,
    fields.end,
    fields.value,
])

export default {
    name: 'TaxRanges',
    components: {
        Range,
    },
    props: {
        value: {
            type: Array,
        },
    },
    data() {
        return {
            ranges: this.value.map(val => formSchema.initialValues(val)),
            valid: true,
            fields,
        }
    },
    created() {
        this.valid = this.validateRanges(this.ranges);
        this.$emit('change', { valid: this.valid });
    },
    methods: {
        castRanges() {
            return this.ranges.map(range => {
                const mappedRange = { ...range };
                mappedRange.end = mappedRange.end || undefined;
                mappedRange.start = mappedRange.start || undefined;
                mappedRange.value = mappedRange.value || undefined;
                return formSchema.cast(mappedRange);
            });
        },
        validateRanges(ranges) {
            for (let i = 0; i < ranges.length; i++) {
                if (ranges[i].start >= ranges[i].end) {
                    return false;
                }
                if (!ranges[i].value) {
                    return false;
                }
                if (i < ranges.length - 2) {
                    for (let j = i + 1; j < ranges.length - 1; j++) {
                        if (ranges[i].end >= ranges[j].end) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        rangeChanged(range) {
            const index = range.index;
            if (!range.val.end && index === this.ranges.length - 2) {
                this.ranges = this.ranges.slice(0, index + 1);
            } else if (range.val.end && index === this.ranges.length - 1) {
                this.ranges.push({
                    start: range.val.end + 1
                });
            } else if (range.val.end) {
                this.ranges[range.index + 1].start = range.val.end + 1;
            }
            const ranges = this.castRanges();
            this.valid = this.validateRanges(ranges);
            this.$emit('change', { ranges, valid: this.valid });
        },
        isReadonly(index) {
            return this.ranges.length > 1 && index < this.ranges.length - 2;
        },
    },
}
</script>