import i18n from '@/shared/plugins/vue-i18n';
import DecimalField from '@/shared/fields/decimal-field';
import IntegerField from '@/shared/fields/integer-field';
import GenericModel from '@/shared/models/generic-model';

const field = (name) => i18n.t(`TAXES.FIELDS.${name.toUpperCase()}`);

const fields = {
    value: new DecimalField(
        'value', 
        field('value'),
        { min: 0, scale: 2 }
    ),
    start: new IntegerField(
        'start',
        field('start'),
        { }
    ),
    end: new IntegerField(
        'end',
        field('end'),
        { }
    ),
}

export class RangeModel extends GenericModel {
    static get fields() {
        return fields;
    }
}