<template>
    <form class="form"
        novalidate="novalidate"
        id="st_manage_function_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
            <label>
                {{ $t('FUNCTIONS.ADD_EDIT_FORM.NAME') }} *
            </label>
            <st-input-text
                v-model="stFunction.name"
                ref="name"
                name="name"
            />
        </div>
        <div class="form-group">
            <label>
                {{ $t('FUNCTIONS.ADD_EDIT_FORM.DESCRIPTION') }}
            </label>
            <b-form-textarea
                v-model="stFunction.description"
                id="textarea-description"
                :placeholder="$t('FUNCTIONS.ADD_EDIT_FORM.PLACEHOLDER')"
                ref="name"
                name="description"
            ></b-form-textarea>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { FunctionModel } from '@/modules/administrator/models/function-model';

const { fields } = FunctionModel;
const formSchema = new FormSchema([
    fields.name
])

import Message from '@/shared/message/message';
import { mapActions } from 'vuex';
export default {
    name: 'ManageFunctionForm',
    props: {
        institution: {
            type: Object,
        },
        selectedFunction: {
            type: Object
        },
        editMode: {
            type: Boolean
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            stFunction: {
                name: '',
                description: '',
                status: 'active',
                is_test: false
            },
        };
    },
    mounted() {
        if(this.editMode) {
            this.stFunction = {
                ...this.stFunction,
                name: this.selectedFunction.name,
                description: this.selectedFunction.description
            };
        }
        this.fv = createFormValidation('st_manage_function_form', this.rules);
    },
    methods: {
        ...mapActions({
            addFunction: 'administrator/addFunction',
            updateFunction: 'administrator/updateFunction',
        }),
        clearModal() {
            this.stFunction = {
                name: '',
                description: '',
                status: 'active',
                is_test: false
            }
        },
        hide() {
            this.$emit('cancelForm');
            this.clearModal();
        },
        manageFunction() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    if (this.editMode) {
                        this.onUpdateFunction();
                    } else {
                        this.onAddFunction();
                    }
                }
            });
        },
        onAddFunction() {
            const orgParams = {
                organisation_id: this.institution.id,
                organisation_name: this.institution.name
            }
            this.addFunction({...orgParams, ...this.stFunction})
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('FUNCTIONS.SUCCESS.ADD');
            })
        },
        onUpdateFunction() {
            this.updateFunction({id: this.selectedFunction.id, payload: this.stFunction})
            .then(() => {
                this.stFunction.id = this.selectedFunction.id;
                this.$emit('submit');
                this.hide();
                Message.success('FUNCTIONS.SUCCESS.UPDATE');
            })
        },
    }
};
</script>
