<template>
    <st-modal
        id="modal-functionary-permissions"
        hide-footer
        hide-header-delimiter
        hide-footer-delimiter
        hideable
        size="lg"
        ref="modal"
        :title="$t('FUNCTIONARY.PERMISSIONS.HEADER')"
    >
        <template #body>
            <!-- filtering section -->
            <div class="d-flex justify-content-between my-4">
                <div class="d-flex align-items-center">
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('FUNCTIONARY.PERMISSIONS.FILTER_PLACEHOLDER')"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="clearFilter"> {{ $t('GENERAL.BUTTON.ERASE') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>

            </div>
                <!-- actual table with data -->
            <st-data-table
                :items="permissionsList"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :per-page="perPage"
                responsive="sm"
                hover
            >
            </st-data-table>

            <!-- paginator -->
            <div class="d-flex justify-content-end mt-6">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="mr-10"
                ></b-pagination>
            </div>
        </template>
    </st-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BPagination, BInputGroupAppend } from 'bootstrap-vue';

export default {
    name: 'FunctionaryPermissionsModal',
    components: {
         'b-pagination': BPagination,
         'b-input-group-append': BInputGroupAppend
    },
    props: {
        userId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            sortBy: 'permission_name',
            sortDesc: false,
            perPage: 10,
            currentPage: 1,
            filter: '',
        }
    },
    computed: {
        ...mapGetters({
            permissions: "administrator/form/getSelectedFunctionaryAccountPermissions",
        }),
        fields() {
            return [
                {
                    label: this.$t('FUNCTIONARY.PERMISSIONS.PERMISSION_NAME_LABEL'),
                    key: 'permission_name',
                }
            ]
        },
        totalRows() {
            return this.filteredData.length || 1;
        },
        filteredData() {
            return this.filter ? this.permissions.filter((el) => el.permission_name?.includes(this.filter)) : this.permissions;
        },
        permissionsList() {
            return this.filteredData.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage,
            );
        },
    },
    methods: {
        ...mapActions({
            getFunctionaryAccountPermissions: "administrator/form/getFunctionaryAccountPermissions",
            resetPermissions: "administrator/form/resetFunctionaryAccountPermissions"
        }),
        hide() {
            this.resetPermissions();
            this.$refs['modal'].hide();
        },
        async show() {
            this.clearFilter();
            await this.getFunctionaryAccountPermissions(this.userId);
            this.$refs['modal'].show();
        },
        clearFilter() {
            this.filter = '';
            this.currentPage = 1;
        },
    },
};
</script>
