import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';

const label = (name) => i18n.t(`URBANISM_REGULATIONS.FIELDS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    zone_key: new StringField('zone_key', label('zone_key'), {
        required: true,
    }),
    category_key: new StringField('category_key', label('category_key'), {
        required: true,
    }),
    tenant_name: new StringField('tenant_name', label('tenant_name'), {
        required: true,
    }),
    content: new StringField('content', label('content')),
    content_html: new StringField('content_html', label('content_html')),
};

export class UrbanismRegulationsModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
