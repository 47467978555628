<template>
    <st-filters-pagination
        stateModule="administrator/territorialUnitAllocation"
        :filters="filters"
        :header="header"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
               <div class="d-flex justify-content-end">
                    <st-button
                        v-can:create="'organisationsTerritorialUnitAllocation'"
                        :disabled="isLoading"
                        :callback="addUat"
                        :spinner="isLoading"
                        class="mr-5"
                    >
                        <i class="fa fa-plus"></i>
                        {{ $t('TERRITORIAL_UNIT_ALLOCATION.BUTTON.ADD_UAT') }}
                    </st-button>
                    <st-button
                        variant="link"
                        :disabled="isLoading"
                        :callback="cancel"
                        :spinner="isLoading"
                    >
                        {{ $t("GENERAL.BUTTON.CANCEL") }}
                    </st-button>
                </div>
            </slot>
        </template>
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { TerritorialUnitAllocationModel } from '@/modules/administrator/models/territorial-unit-allocation-model';

const { fields } = TerritorialUnitAllocationModel;

const filterSchema = new FilterSchema([
    fields.tu_name,
]);

export default {
    name: 'TerritorialUnitAllocationListFilter',
    props: {
        user:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            filters: filterSchema.fields,
        }
    },
    computed: {
        ...mapGetters({
            total: 'administrator/territorialUnitAllocation/total',
            loading: 'shared/loading',
        }),
        isLoading(){
            return this.loading['admin/fetchTerritorialUnitAllocation'];
        },
        header() {
            return `${this.$t('TERRITORIAL_UNIT_ALLOCATION.HEADER')} - ${this.user.first_name} ${this.user.last_name}` ;
        }
    },
    methods: {
        ...mapActions({
            doFetch: 'administrator/territorialUnitAllocation/fetchTerritorialUnitAllocation',
        }),
        refresh() {
            this.doFilter(this.params);
        },
        doFilter(params) {
            this.params = params;
            this.doFetch({payload:params, id: this.user.user_id});
        },
        addUat() {
            this.$emit('addUAT', this.user);
        },
        cancel() {
            this.$emit('cancelForm', true);
        }
    },
}
</script>
