<template>
    <st-page
        layout="tab"
        :showHeader="false"
    >
        <function-list-filter :selectedInstitution="selectedInstitution" ref="listFuncFilter">
            <template #toolbar>
                <function-list-toolbar :selectedInstitution="selectedInstitution" @addFunction=addFunction />
            </template>
            <function-list-table
                :selectedInstitution="selectedInstitution"
                @editFunction="editFunction"
                @updateFunction="updateFunction"
            />
        </function-list-filter>
        <manage-function
            ref="manage-function"
            :institution="selectedInstitution"
            :selectedFunction="selectedFunction"
            :editMode="editMode"
            @updateFunction="updateFunction"
        />
    </st-page>
</template>

<script>
    import FunctionListFilter from '../components/functions/FunctionListFilter';
    import FunctionListTable from '../components/functions/FunctionListTable';
    import FunctionListToolbar from '../components/functions/FunctionListToolbar';
    import ManageFunction from '@/modules/administrator/components/functions/ManageFunctionModal.vue';
    export default {
        name: 'FunctionList',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        components: {
            FunctionListFilter,
            FunctionListTable,
            FunctionListToolbar,
            ManageFunction
        },
        data() {
            return {
                selectedFunction: {},
                editMode: false,
            };
        },
        methods: {
            showModal() {
                this.$refs['manage-function'].show();
            },
            addFunction() {
                this.editMode = false;
                this.showModal();
            },
            updateFunction() {
                this.$refs.listFuncFilter.refresh();
            },
            editFunction(data) {
                this.editMode = true;
                this.selectedFunction = data.item;
                this.showModal();
            }
        }
    }
</script>
