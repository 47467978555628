<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :loading="isLoading"
            :modelPresenter="presenter"
            @edit="editAccount"
            @delete="deleteAccount"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { CollectorAccountModel } from '@/modules/administrator/models/collector-account-model';

    const { fields } = CollectorAccountModel;

    export default {
        name: 'CollectorAccountListTable',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                presenter: CollectorAccountModel.presenter,
            };
        },
        computed: {
            ...mapState({
                loading: state => state.shared.loading,
            }),
            ...mapGetters({
                rows: 'administrator/colAccList/rows',
                orgPermissions: 'administrator/orgList/orgPermissions',
            }),
            isLoading () {
                return this.loading['admin/getCollectorAccounts'] ;
            },
            fields() {
                return [
                    fields.name,
                    fields.bank,
                    fields.username.extendField({ bold: true }),
                    fields.password.extendField({ password: true }),
                    fields.status.extendField({ labelType: true })
                ]
            },
            actions() {
                const actions = [];
                if (this.orgPermissions.hasPermissionToEditBankAccounts) {
                    actions.push({
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('COLLECTOR_ACCOUNTS.TOOLTIPS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToDestroyBankAccounts) {
                    actions.push({
                        name: 'delete',
                        tooltipText: this.$t('COLLECTOR_ACCOUNTS.TOOLTIPS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        disabled: false,
                        type: 'danger'
                    })
                }

                return actions
            }
        },
        methods: {
            ...mapActions({
                removeAccount: 'administrator/colAccList/deleteAccount'
            }),
            deleteAccount(data) {
                this.removeAccount(data.item.id)
                .then(() => {
                    this.$emit('updateAccount');
                    this.$alert({
                        type: 'success',
                        text: this.$t('COLLECTOR_ACCOUNTS.SUCCESS.DELETE'),
                        cancelButtonText: this.$t('GENERAL.CLOSE'),
                        showCancelButton: true,
                        showConfirmButton: false,
                    });
                })
            },
            editAccount(data) {
                this.$emit('editCollectorAccount', data);
            },

        },
    };
</script>
