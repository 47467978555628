import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import DecimalField from '@/shared/fields/decimal-field';
import ApiService from '@/shared/services/api.service';
import EnumField from '@/shared/fields/enum-field';

const field = (name) => i18n.t(`TAXES.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`TAXES.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`TAXES.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    name: new StringField(
        'name',
        field('name'),
        {
            required: true,
        },
        {
            placeholder: placeholder('name'),
        },
    ),
    entry_date: new DateField(
        'entry_date',
        field('entry_date'),
        {
            required: true,
        },
    ),
    justification: new StringField(
        'justification',
        field('justification'),
        {
            required: true,
            max: 60,
        },
    ),
    category_id: new RelationToOneField(
        'category_id',
        field('category'),
        () => {
            return new Promise((resolve) => {
                ApiService.query('tax-categories', {
                    params: { query: `status eq 'active'`, limit: 100 }
                })
                    .then(({ data }) => {
                        resolve(data.data);
                    })
            })
        },
        (record) => {
            return {
                value: record.id,
                text: record.name,
            }
        },
        {
            required: true,
        },
        {
            placeholder: placeholder('category'),
        },
    ),
    price_calculation_model: new EnumField(
        'price_calculation_model',
        field('price_calculation_model'),
        [
            {
                value: 'fixed',
                name: 'fixed',
                text: enumLabel('price_calculation_model', 'fixed'),
                type: 'primary',
            },
            {
                value: 'range',
                name: 'range',
                text: enumLabel('price_calculation_model', 'range'),
                type: 'primary',
            },
            {
                value: 'mixed',
                name: 'mixed',
                text: enumLabel('price_calculation_model', 'mixed'),
                type: 'primary',
            },
            {
                value: 'percentage',
                name: 'percentage',
                text: enumLabel('price_calculation_model', 'percentage'),
                type: 'primary',
            },
        ],
        { required: true },
    ),
    fixed_value: new DecimalField(
        'fixed_value',
        field('fixed_value'),
        {
            required: true,
            min: 0,
            scale: 2
        },
    ),

    percentage_value: new DecimalField(
        'percentage_value',
        field('percentage_value'),
        {
            required: true,
            min: 0,
            scale: 2
        }
    ),
}

export class TaxModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
