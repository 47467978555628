<template>
    <div>
        <st-data-table
            :items="accounts"
            :fields="fields"
            :loading="isLoading"
            :modelPresenter="presenter"
            responsive="sm"
            hover
            :actions="actions"
            @delete="deleteFunctionaryAccount"
            @edit="openEditAccountPage"
            @status="changeAccountStatus"
            @password="resetPassword"
            @permissions="showPermissions"
            @assign="assignUserToUAT"
        >
        </st-data-table>

        <functionary-permissions-modal
            ref="functionaryPermissionsModal"
            :userId="selectedFunctionary"
        />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Message from '@/shared/message/message';
    import FunctionaryPermissionsModal from '@/modules/administrator/components/users/FunctionaryPermissionsModal';
    import { FunctionaryModel } from "@/modules/administrator/models/functionary-model";
    const { fields } = FunctionaryModel;

    export default {
        name: 'FunctionaryListTable',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        components: {
            FunctionaryPermissionsModal,
        },
        data() {
            return {
                editAccount: false,
                presenter: FunctionaryModel.presenter,
                selectedFunctionary: '',
            };
        },
        computed: {
            ...mapGetters({
                accounts: 'administrator/list/rows',
                orgPermissions: 'administrator/orgList/orgPermissions',
                loading: 'shared/loading',
            }),
            isLoading () {
                return this.loading["administrator/fetchFunctionaryAccounts"];
            },
            fields() {
                return [
                    fields.full_name,
                    fields.email,
                    fields.status.extendField({ labelType: true }),
                    fields.departments,
                    fields.functions,
                    fields.account_type
                ]
            },
            actions() {
                const actions = [{
                    name: "permissions",
                    icon: '<i class="far fa-id-card"></i>',
                    tooltipText: this.$t('FUNCTIONARY.PERMISSIONS_TOOLTIP'),
                    customIcon: true,
                    type: "primary",
                }];
                if (this.orgPermissions.hasPermissionToEditUsers) {
                    actions.push(
                        {
                            name: "edit",
                            icon: "edit",
                            tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                            customIcon: false,
                            type: "primary",
                        },
                        {
                            name: "status",
                            icon: "redo-alt",
                            tooltipText: this.$t('FUNCTIONARY.CHANGE_ACCOUNT_STATUS_ACTION'),
                            customIcon: false,
                            type: "primary",
                        },
                    )
                }
                if (this.orgPermissions.hasPermissionToResetPasswordUsers) {
                    actions.push({
                        name: "password",
                        icon: "key",
                        tooltipText: this.$t('FUNCTIONARY.RESET_PASSWORD'),
                        customIcon: false,
                        type: "primary",
                    })
                }
                if (this.orgPermissions.hasPermissionToViewTerritorialUnitAllocation) {
                    actions.push({
                            name: "assign",
                            icon: "share-square",
                            tooltipText: this.$t('FUNCTIONARY.TOOLTIPS.TERRITORIAL_UNIT_ALLOCATION'),
                            customIcon: false,
                            type: "primary",
                    })
                }
                if (this.orgPermissions.hasPermissionToDestroyUsers) {
                    actions.push({
                        name: "delete",
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: "trash-alt",
                        customIcon: false,
                        type: "danger",
                    })
                }

                return actions;
            }
        },
        methods: {
            ...mapActions({
                selectFunctionaryAccount: "administrator/list/selectFunctionaryAccount",
                changeStatus: "administrator/form/changeAccountStatus",
                deleteAccount: "administrator/form/deleteFunctionaryAccount",
                sendPasswordResetEmail: "auth/sendPasswordResetEmail",
                getFunctionaryProfile: "administrator/form/getFunctionaryProfile"
            }),
            deleteFunctionaryAccount(data) {
                if (data.item.status !== "active") {
                    this.deleteAccount(data.item.user_id)
                        .then(() => {
                            Message.success('FUNCTIONARY.DELETED_ACCOUNT_SUCCESS');
                            this.$emit('updateFunctionaryList');
                        });
                } else {
                    Message.warning('FUNCTIONARY.DENY_DELETE_ACCOUNT');
                }
            },
            openEditAccountPage(account) {
                this.selectFunctionaryAccount(account.item);
                this.getFunctionaryProfile(account.item.user_id).then(() => {
                    this.$emit('editFunctionary', account.item.user_id)
                });
            },
            changeAccountStatus(account) {
                const status = this.$t(`FUNCTIONARY.ENUMS.STATUS.${account.item.status.toUpperCase()}`);
                this.$alert({
                    type: 'warning',
                    isTextHtml: true,
                    text: this.$t('FUNCTIONARY.CHANGE_ACCOUNT_STATUS', {status}),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        const params = {
                            id: account.item.user_id,
                            payload: {
                                status: account.item.status === "active" ? "inactive" : "active",
                            },
                        };

                        this.changeStatus(params)
                            .then(() => {
                                Message.success('FUNCTIONARY.CHANGE_STATUS_SUCCESS')
                                this.$emit('updateFunctionaryList');
                            });
                        }
                });
            },
            resetPassword(account) {
                if (account.item.status === "active") {
                    const email = account.item.user_email;
                    this.$alert({
                        type: 'warning',
                        text: this.$t('FUNCTIONARY.RESET_PASSWORD_CONFIRM'),
                        confirmButtonText: this.$t('GENERAL.YES'),
                        cancelButtonText: this.$t('GENERAL.NO'),
                        hasConfirmation: true,
                        confirmCallback: () => {
                            this.sendPasswordResetEmail({ email }).then(() => {
                                this.$alert({
                                    type: 'success',
                                    text: this.$t('FUNCTIONARY.RESET_PASS_SUCCESS', { email }),
                                    isTextHtml: true,
                                    cancelButtonText: this.$t('GENERAL.CLOSE'),
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                });
                            });
                        }
                    });
                } else {
                    Message.warning('FUNCTIONARY.DENY_RESET_PASSWORD');
                }
            },
            showPermissions(account) {
                this.selectedFunctionary = account.item.user_id;
                this.$nextTick(() => {
                    this.$refs.functionaryPermissionsModal.show();
                });
            },
            assignUserToUAT(user) {
                this.$emit('assignUserToUAT', user.item)
            }
        },
    };
</script>
