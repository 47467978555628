<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st-tax-form"
        >
            <div class="form-group">
                <label>
                    {{ fields.name.label }} *
                </label>
                <st-input-text
                    :name="fields.name.name"
                    v-model="model[fields.name.name]"
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.category_id.label }} *
                </label>
                <b-form-select
                    :name="fields.category_id.name"
                    v-model="model[fields.category_id.name]"
                    :options="fields.category_id.options"
                >
                </b-form-select>
            </div>

            <div class="form-group">
                <label>
                    {{ fields.price_calculation_model.label }} *
                </label>
                <b-form-select
                    v-model="model[fields.price_calculation_model.name]"
                    :options="fields.price_calculation_model.options"
                    :ref="fields.price_calculation_model.name"
                    :name="fields.price_calculation_model.name"
                >
                </b-form-select>
            </div>

            <tax-ranges 
                v-show="showRanges"
                :value="ranges"
                @change="rangesChanged"
            />

            <div v-show="showFixedValue" class="form-group">
                <label>
                    {{ fields.fixed_value.label }} *
                </label>
                <b-form-input 
                    :name="fields.fixed_value.name"
                    v-model="model[fields.fixed_value.name]"
                    v-positiveDecimalOnly 
                />
            </div>

            <div v-show="showPercentageValue" class="form-group">
                <label>
                    {{ fields.percentage_value.label }} *
                </label>
                <b-form-input 
                    :name="fields.percentage_value.name"
                    v-model="model[fields.percentage_value.name]"
                    v-positiveDecimalOnly 
                />
            </div>

            <div class="form-group">
                <label>
                    {{ fields.entry_date.label }} *
                </label>
                <st-datepicker
                    :name="fields.entry_date.name"
                    v-model="model[fields.entry_date.name]"
                    @change="validateField(fields.entry_date.name)"
                >
                </st-datepicker>
            </div>

            <div class="form-group">
                <label>
                    {{ $t('TAXES.MODALS.HCL') }} *
                </label>
                <st-input-text
                    :name="fields.justification.name"
                    v-model="model[fields.justification.name]"
                />
            </div>
        </form>

        <div class="pt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel"/>
        </div>
    </div>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { TaxModel } from './models/tax-model';
import TaxRanges from './TaxRanges.vue';

const { fields } = TaxModel;

const formSchema = new FormSchema([
    fields.name,
    fields.category_id,
    fields.fixed_value,
    fields.percentage_value,
    fields.justification,
    fields.entry_date,
    fields.price_calculation_model,
])

export default {
    name: 'TaxForm',
    props: {
        tax: {
            type: Object,
            default: () => ({ }),
        }
    },
    components: {
        TaxRanges,
    },
    data() {
        return {
            model: formSchema.initialValues(this.tax),
            fields,
            rules: formSchema.rules(),
            ranges: this.tax.ranges || [{
                start: 1,
            }],
            rangesValid: true,
        }
    },
    mounted() {
        this.fv = createFormValidation('st-tax-form', this.rules);
        if (this.model.price_calculation_model !== 'mixed'
            || this.model.price_calculation_model !== 'fixed') {
            this.fv.disableValidator(fields.fixed_value.name);
        }
        if (this.model.price_calculation_model !== 'percentage') {
            this.fv.disableValidator(fields.percentage_value.name);
        }
    },
    methods: {
        async submit() {
            const validate = await this.fv.validate();
            
            if (validate === 'Valid') {
                const cast = formSchema.cast(this.model);
                if (this.showRanges) {
                    if (!this.rangesValid) {
                        return;
                    }
                    cast.ranges = this.ranges;
                }
                if (this.model.price_calculation_model === 'fixed') {
                    cast.ranges = undefined;
                }
                if (this.model.price_calculation_model === 'range') {
                    cast.fixed_value = undefined;
                }
                if (this.model.price_calculation_model === 'percentage') {
                    cast.ranges = undefined;
                    cast.fixed_value = undefined;
                }
                this.$emit('submitForm', cast);
            }
        },
        cancel() {
            this.$emit('cancelForm');
        },
        validateField(field) {
            this.fv.validateField(field);
        },
        rangesChanged({ ranges, valid }) {
            if (ranges) {
                this.ranges = ranges;
            }
            this.rangesValid = valid;
        },
    },
    computed: {
        showFixedAndRanges() {
            return this.model.price_calculation_model === 'mixed';
        },
        showFixedValue() {
            return this.model.price_calculation_model === 'fixed'
                || this.showFixedAndRanges;
        },
        showPercentageValue() {
            return this.model.price_calculation_model === 'percentage';
        },
        showRanges() {
            return this.model.price_calculation_model === 'range'
                || this.showFixedAndRanges;
        }
    },
    watch: {
        showFixedValue: function (val) {
            if (!val) {
                this.fv.disableValidator(fields.fixed_value.name);
            } else {
                this.fv.enableValidator(fields.fixed_value.name);
            }
        },
    },
}
</script>