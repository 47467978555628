<template>
    <div>
        <st-modal
            id="st-add-user-to-uat"
            hide-header-delimiter
            hide-footer-delimiter
            no-close-on-backdrop
            size="md"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                    {{ $t('TERRITORIAL_UNIT_ALLOCATION.MODAL.HEADER') }}
                </p>
            </template>
            <template #body>
                <add-user-to-UAT-form
                    ref="add-user-to-UAT-form"
                    :user="user"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </add-user-to-UAT-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="addUAT"
                    >
                        {{ $t('GENERAL.BUTTON.ADD') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>
<script>
import AddUserToUATForm from './AddUserToUATForm.vue';
export default {
    name: 'AddUserToUATModal',
    components: {
        AddUserToUATForm
    },
    props: {
        user:{
            type: Object,
            required: true
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        addUAT() {
            this.$refs['add-user-to-UAT-form'].addUAT();
        },
        submit() {
            this.hide();
            this.$emit('UatUpdated');
        }
    }
};
</script>
