<template>
    <div>
        <Loader v-if="loading['admin/getFunctions']"/>
        <st-modal
            id="st-associated-functions-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hideable
            size="md"
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ $t('DEPARTMENTS.ASSOCIATED_FCT_MODAL.HEADER') }}</p>
            </template>
                <template #body>
                    <div class="d-flex justify-content-center mb-6">
                        <p class="font-size-h6">{{department.name}}</p>
                    </div>
                    <div class="ml-10 associated-functions-wrapper">
                        <b-form-group >
                            <b-form-checkbox
                                v-for="(option) in functions"
                                v-model="selected"
                                :key="option.id"
                                :value="option.id"
                                name="departmentFunction"
                                stacked
                                size="lg"
                                >
                                {{ option.name }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-start">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="confirm"
                    > {{ $t('GENERAL.BUTTON.CONFIRM') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex';
export default {
    name: 'AssociatedFunctionsModal',
    props: {
        department: {
            type: Object,
            required: true
        },
        institution: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selected: [],
        };
    },
    computed: {
        ...mapState({
            loading: state => state.shared.loading,
        }),
        ...mapGetters({
            functions: 'administrator/functions',
            depFunctions: 'administrator/depFunctions',
        }),
    },
    methods: {
        ...mapActions({
            getFunctions: 'administrator/getFunctions',
            getDepartmentFunctions: 'administrator/getDepartmentFunctions',
            addFunctionToDepartment: 'administrator/addFunctionToDepartment',
            addFunctionsToDepartment: 'administrator/addFunctionsToDepartment',
            deleteFunctionFromDepartment: 'administrator/deleteFunctionFromDepartment',
        }),
        hide() {
            this.selected = [];
            this.$refs['modal'].hide();
        },
        show() {
            this.getFunctions(this.institution.id).then(() => {
                this.getDepartmentFunctions(this.department.id)
                .then(() => {
                    const departmentFunctions = this.depFunctions.map(x => x.function_id)
                    if (departmentFunctions.length > 0 ) {
                        this.selected = departmentFunctions;
                    }
                })
            });
            this.$refs['modal'].show();
        },
        confirm() {
            const idList = this.depFunctions.map(functionItem => functionItem.function_id);

            const functionsRemoved = idList.filter(id => !this.selected.includes(id));
            const functionsAdded = idList.filter(id => this.selected.includes(id)).concat(this.selected.filter(id => !idList.includes(id)));

            if (functionsRemoved.length > 0 && functionsAdded.length > 0) {
                // Functions added and removed
                this.addAsignatedFunctions(functionsAdded)
                .then(()=> {
                    this.removeAsignatedFunctions(functionsRemoved)
                    .then(() => this.hide());
                })
            } else if (functionsRemoved.length === 0 && functionsAdded.length > 0) {
                // Functions only added
                this.addAsignatedFunctions(functionsAdded)
                .then(()=> this.hide())
            } else if (functionsRemoved.length > 0 && functionsAdded.length === 0) {
                // Functions only removed
                this.removeAsignatedFunctions(functionsRemoved)
                .then(() => this.hide())
            }
        },
        async removeAsignatedFunctions (list) {
            const payloadList = this.functionList({list, action: 'delete'});
            const promises = [];
            payloadList.forEach((payload) => {
                const prms = this.deleteFunctionFromDepartment(payload)
                promises.push(prms);
            })
            await Promise.all(promises);
        },
        async addAsignatedFunctions(list) {
            const payload = this.functionList({list, action: 'add'})
            await this.addFunctionsToDepartment(payload);
        },
        functionList({list, action}) {
            return list.map(item => {
                const functionFound = this.functions.find((functionItem) => functionItem.id === item);
                let comment = ''
                switch (action) {
                    case 'add':
                        comment =  `Add function ${functionFound.name} to department`;
                        break;
                    case 'delete':
                        comment = `Remove function ${functionFound.name} from department`;
                        break;
                }
                return {
                    action,
                    comment,
                    child_id: functionFound.id,
                    child_type: 'function',
                    parent_id: this.department.id,
                    parent_type: 'department',
                };
            })
        }
    }
};
</script>
<style scoped lang="scss">
    .associated-functions-wrapper {
        overflow: auto;
        max-height: 280px;
        margin-left: 2.5rem !important;
    }

</style>
