import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class DepartmentsField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                return new Promise((resolve) => {
                    if (params.org_id) {
                        ApiService.setBaseUrl({isArchive: false})
                        ApiService.query('departments', {params: { query: `organisation_id eq ${params?.org_id}` , limit: 100}})
                            .then(({data}) => resolve(data.data))
                    } else {
                        resolve([])
                    }
                    
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
