<template>
    <st-page
        layout="tab"
        :showHeader="false"
    >
        <collector-account-list-filter :selectedInstitution="selectedInstitution" ref="listCAFilter">
            <template #toolbar>
                <st-button
                    v-can:create="'organisationsBankAccounts'"
                    variant="primary"
                    :callback="addCollectorAccount"
                >
                    <i class="fa fa-plus"></i>
                    {{ $t('COLLECTOR_ACCOUNTS.BUTTON.ADD_COLLECTOR_ACCOUNT') }}
                </st-button>

            </template>
            <collector-account-list-table
                :selectedInstitution="selectedInstitution"
                @editCollectorAccount="editCollectorAccount"
                @updateAccount="updateAccount"
            />
        </collector-account-list-filter>

         <manage-collector-account
            ref="manage-collector-account"
            @updateAccount="updateAccount"
            :selectedAccount="selectedCollectorAccount"
            :edit-mode="editMode"
        />
    </st-page>
</template>

<script>
    import CollectorAccountListFilter from '../components/collectorAccounts/CollectorAccountListFilter';
    import CollectorAccountListTable from '../components/collectorAccounts/CollectorAccountListTable';
    import ManageCollectorAccount from '@/modules/administrator/components/collectorAccounts/ManageAccount.vue';
    export default {
        name: 'DepartmentList',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        components: {
            CollectorAccountListFilter,
            CollectorAccountListTable,
            ManageCollectorAccount
        },
        data() {
            return {
                selectedCollectorAccount: {},
                editMode: false,
            };
        },
        methods: {
            showModal() {
                this.$refs['manage-collector-account'].show();
            },
            addCollectorAccount() {
                this.editMode = false;
                this.showModal();
            },
            updateAccount() {
                this.$refs.listCAFilter.refresh();
            },
            editCollectorAccount(data) {
                this.editMode = true;
                this.selectedCollectorAccount = data.item;
                this.showModal();
            }
        }
    }
</script>
