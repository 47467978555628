<template>
<st-page
    layout="tab"
    :showHeader="false"
>
    <div class="p-8">
        <form class="form row"
            novalidate="novalidate"
            id="st_edit_institution_form"
            @submit.stop.prevent="onSubmit()"
        >
            <div class="col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.name.label }} *
                    </label>
                    <st-input-text
                        v-model="model[fields.name.name]"
                        :ref="fields.name.name"
                        :name="fields.name.name"
                    />
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.type.label }}*
                    </label>
                    <b-form-select
                        v-model="model[fields.type.name]"
                        :options="fields.type.options"
                        value-field="value"
                        text-field="name"
                        :ref="fields.type.name"
                        :name="fields.type.name"
                        @change="onChange($event, fields.type)"
                    ></b-form-select>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.description.label }}
                    </label>
                    <b-form-textarea
                        v-model="model[fields.description.name]"
                        :ref="fields.description.name"
                        :name="fields.description.name"
                        id="textarea-description"
                        :placeholder="fields.description.placeholder"
                    ></b-form-textarea>
                </div>
                <div v-show="model[fields.type.name]" class="form-group">
                    <label v-show="model[fields.type.name] === 'county_council'">
                        {{ $t('INSTITUTION.FIELDS.UAT') }} *
                    </label>
                    <label v-show="isCityHallOrServices">
                        {{ fields.county_id.label }} *
                    </label>
                    <st-autocomplete
                        v-model="model[fields.county_id.name]"
                        :options="fields.county_id.options"
                        :ref="fields.county_id.name"
                        :name="fields.county_id.name"
                        :disabled="true"
                        :model="model"
                        :formValidation="fv"
                        :fields="fields"
                    ></st-autocomplete>
                </div>
                <div  v-show="isCityHallOrServices" class="form-group">
                    <label>
                        {{ $t('INSTITUTION.FIELDS.UAT') }} <span v-if="model[fields.type.name] !== 'services'">*</span>
                    </label>
                    <st-autocomplete
                        v-model="model[fields.locality_id.name]"
                        :options="fields.locality_id.options"
                        :ref="fields.locality_id.name"
                        :name="fields.locality_id.name"
                        :disabled="!isCityHallOrServices"
                        :model="model"
                        :formValidation="fv"
                        :fields="fields"
                    ></st-autocomplete>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.external_link.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.external_link.name]"
                        :ref="fields.external_link.name"
                        :name="fields.external_link.name"
                    />
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.op_details_text.label }}
                    </label>
                    <b-form-textarea
                        v-model="model[fields.op_details_text.name]"
                        :ref="fields.op_details_text.name"
                        :name="fields.op_details_text.name"
                        :rows="5"
                    ></b-form-textarea>
                </div>
                <div class="mb-6">
                    <label>
                        {{ fields.status.label }}
                    </label>
                    <b-form-radio-group
                        size="lg"
                        v-model="model[fields.status.name]"
                        :name="fields.status.name"
                        :options="fields.status.options"
                    ></b-form-radio-group>
                </div>
                <hr class="pt-10"/>
                <div>
                    <b-form-checkbox
                        size="lg"
                        id="test_institution"
                        v-model="model[fields.test_org.name]"
                        :name="fields.test_org.name"
                        :value="true"
                        :unchecked-value="false"
                    >
                    {{ fields.is_test.placeholder }}
                    </b-form-checkbox>
                </div>
                <div>
                    <b-form-checkbox
                        size="lg"
                        v-model="model[fields.is_citizen_visible.name]"
                        :name="fields.is_citizen_visible.name"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="isInactive"
                    >
                    {{ fields.is_citizen_visible.label }}
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-center">
                    <img class="mw-200" :src="defaultAvatar" alt="" v-if="!this.selectedInstitution.logo"/>
                    <img class="mw-200" :src="logoSrc" v-if="this.selectedInstitution.logo"/>
                </div>

                <div class="d-flex justify-content-center">
                    <st-file
                        :maxFiles="1"
                        :maxFileSize="10"
                        :acceptedFileTypes="['image/jpeg','image/png']"
                        @change="uploadLogo"
                        :value="[]"
                    >
                    </st-file>
                </div>
            </div>
        </form>

        <div class="d-flex justify-content-between w-100 mt-10">
            <st-button
                variant="link"
                class="font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="discard"
            >
                {{ $t('INSTITUTION.BUTTON.CANCEL') }}
            </st-button>
            <st-button
                variant="primary"
                class="font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="save"
            >
                <span> {{ $t('INSTITUTION.BUTTON.SAVE') }}</span>
            </st-button>
        </div>
    </div>
</st-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Message from '@/shared/message/message';
import { OrganisationModel } from "@/modules/administrator/models/organisation-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = OrganisationModel;

const formSchema = new FormSchema([
    fields.name,
    fields.description,
    fields.county_id,
    fields.locality_id,
    fields.status,
    fields.test_org,
    fields.type,
    fields.external_link,
    fields.is_citizen_visible,
    fields.op_details_text
]);

export default {
    name: 'EditInstitution',
    props: {
        selectedInstitution: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fields,
            model: formSchema.initialValues(this.selectedInstitution),
            rules: formSchema.rules(),
            fv: null,
            logo: null,
            inactiveStatus: 'inactive'
        };
    },
    computed: {
        ...mapGetters({
            defaultAvatar: 'shared/defaultAvatar',
            uploadAccessToken: 'administrator/uploadAccessToken',
            fileToken: 'administrator/fileToken',
        }),
        isCityHallOrServices() {
            return ['city_hall', 'services'].includes(this.model.type);
        },
        logoSrc() {
            return `${window.VUE_APP_API_URL}/files/${this.selectedInstitution.logo}?token=${this.fileToken}`;
        },
        isInactive() {
            return this.model.status === this.inactiveStatus;
        }
    },
    watch: {
        'model.type': {
            handler() {
                if (this.model.type === 'city_hall') {
                    this.fv.enableValidator(fields.locality_id.name);
                } else {
                    this.fv.disableValidator(fields.locality_id.name);
                }
            },
        },
        'model.status': {
            handler() {
                if (this.model.status === this.inactiveStatus) {
                    this.model.is_citizen_visible = false;
                }
            }
        }
    },
    methods: {
        ...mapActions({
            updateInstitution: 'administrator/orgForm/updateInstitution',
            getInstitution: 'administrator/getInstitution',
            doUpload: 'administrator/orgForm/upload',
        }),
        discard() {
            this.$router.back();
        },
        async save() {
            const status = await this.fv.validate();
            if (status === 'Valid') {
                const counties = this.fields.county_id.options;
                const selectedCounty = counties.find( county => county.value === this.model.county_id);

                const localities = this.fields.locality_id.options;
                const selectedLocality = localities.find( locality => locality.value === this.model.locality_id);

                const payload = {
                    ...this.model,
                    county_name: selectedCounty.text,
                    locality_name: selectedLocality?.text,
                }

                const updatedRecord = await this.updateInstitution({id: this.selectedInstitution.id, payload });
                if (this.logo) {
                    const formData = new FormData();
                    if (this.logo instanceof File) {
                        formData.append("files", this.logo);
                    }

                    const uploadedLogo = await this.doUpload({
                        files: formData,
                        uploadAccessToken: this.uploadAccessToken,
                    });

                    this.logo = uploadedLogo.map((el) => ({file_id: el.id}))

                    await this.updateInstitution({
                        id: this.selectedInstitution.id,
                        payload: {
                            logo: this.logo[0].file_id
                        },
                    });
                }

                if (updatedRecord) {
                    const data = this.logo ? {...this.model, logo: this.logo[0].file_id} : this.model;
                    this.$emit('updateInstitutions', data);
                    this.fv.resetForm();
                    Message.success('INSTITUTION.SUCCESS.UPDATE_ORG');
                }
            }
        },
        onChange(value, field) {
            this.fv.revalidateField(field.name, true);
            if (field?.children) {
                field.children.forEach((child) => {
                    const el = this.fields[child.name];
                    el.fetchParams = { [child.key] : value };
                    this.model[child.name] = '';
                    el.updateOptions();
                    if (this.fv.fields[child.name]) {
                        this.fv.revalidateField(child.name, true);
                    }
                });
            }
        },
        async uploadLogo(documents) {
            if (documents?.length && documents.length === 1) {
                this.logo = documents[0];
            }
        }
    },
    mounted() {
        this.fv = createFormValidation('st_edit_institution_form', this.rules);
        this.fv.disableValidator(fields.locality_id.name);
    },
};
</script>

<style scoped>
    .mw-200 {
        max-width: 200px;
    }
</style>
