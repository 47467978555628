<template>
    <div>
        <b-form-row>
            <b-col>
                <div class="form-group">
                    <b-form-input
                        v-model.number="range.start"
                        v-positiveIntegerOnly
                        :readonly="true"
                        :name="fields.start.name"
                    />
                </div>
            </b-col>
            <b-col>
                <div class="form-group">
                    <b-form-input
                        v-model.number="range.end"
                        v-positiveIntegerOnly
                        :readonly="readonly"
                        :name="fields.end.name"
                    />
                </div>
            </b-col>
            <b-col>
                <div class="form-group">
                    <b-form-input
                        v-model="range.value"
                        v-positiveDecimalOnly 
                        :name="fields.value.name"
                    />
                </div>
            </b-col>
        </b-form-row>
    </div>
</template>

<script>
import { RangeModel } from './models/range-model';

const { fields } = RangeModel;

export default {
    props: {
        range: {
            type: Object,
        },
        index: {
            type: Number,
        },
        readonly: {
            type: Boolean,
        },
    },
    data() {
        return {
            fields,
        }
    },
    watch: {
        range: {
            handler() {
                this.$emit('change', { val: this.range, index: this.index });
            },
            deep: true,
        },
    },
}
</script>