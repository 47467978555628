import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';

const field = (name) => i18n.t(`FUNCTIONS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`FUNCTIONS.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    name: new StringField(
        'name',
        field('name'),
        { required: true, alphabetical: true },
        { placeholder: placeholder('name')},
    ),
    description: new StringField(
        'description',
        field('description'),
        { required: false,},
        { placeholder: placeholder('description')},
    ),
};

export class FunctionModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
