<template>
    <st-filters-pagination
        stateModule="administrator/colAccList"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :header="$t('COLLECTOR_ACCOUNTS.LIST.TITLE')"
        :showErase="false"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
            </slot>
        </template>

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { CollectorAccountModel } from '@/modules/administrator/models/collector-account-model';

    const { fields } = CollectorAccountModel;

    const filterSchema = new FilterSchema([
        fields.name,
        fields.bank,
        fields.username,
        fields.status,
    ]);

    export default {
        name: 'CollectorAccountListFilter',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                filters: filterSchema.fields,
            };
        },
        computed: {
            ...mapGetters({
               total: 'administrator/colAccList/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'administrator/colAccList/getCollectorAccountsByOrg',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch({payload: params, id: this.selectedInstitution.id});
            },
        },

    };
</script>
