<template>
    <div>
        <st-modal
            id="st-manage-decision-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <div v-if="editMode">
                    <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                        {{ $t('DECISION.ADD_EDIT_FORM.HEADER_EDIT') }}
                    </p>
                </div>
                <div v-else>
                    <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                        {{ $t('DECISION.ADD_EDIT_FORM.HEADER_ADD') }}
                    </p>
                </div>
            </template>
            <template #body>
                <manage-decision-form
                    ref="manage-decision-form"
                    :institution="institution"
                    :selectedDecision="selectedDecision"
                    :editMode="editMode"
                    :isExtension="isExtension"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </manage-decision-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageDecision"
                    >
                        <span v-if="editMode"> {{ $t('GENERAL.BUTTON.UPDATE') }}</span>
                        <span v-else> {{ $t('GENERAL.BUTTON.ADD') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ManageDecisionForm from './ManageDecisionForm.vue';

export default {
    name: 'ManageDecision',
    components: {
        ManageDecisionForm
    },
    props: {
        institution: {
            type: Object,
        },
        selectedDecision: {
            type: Object,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        isExtension: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manageDecision() {
            this.$refs['manage-decision-form'].manageDecision();
        },
        submit() {
            this.$emit('updateDecision');
        }
    }
};
</script>
