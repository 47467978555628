<template>
    <div>
        <st-button
            v-can:create="'organisationsFunctions'"
            variant="primary"
            :callback="showModal"
        >
            <i class="fa fa-plus"></i>
            {{ $t('FUNCTIONS.BUTTON.ADD_FUNCTION') }}
        </st-button>
    </div>
</template>

<script>
    export default {
        name: 'FunctionListToolbar',
        methods: {
            showModal() {
                this.$emit('addFunction');
            }
        }
    };
</script>
