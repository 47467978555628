<template>
    <st-filters-pagination
        stateModule="administrator/urbanismRegulationZonesList"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('URBANISM_REGULATION_ZONES.LIST.TITLE')"
        :moreFilters="false"
        :showErase="false"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
            </slot>
        </template>

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { UrbanismRegulationZonesModel } from '@/modules/administrator/models/urbanism-regulation-zones-model';
    import { ORGANISATION_TYPE } from '@/shared/utils/organisation-type';

    const { fields } = UrbanismRegulationZonesModel;

    const filterSchema = new FilterSchema([
        fields.name,
        fields.key,
        fields.type,
    ]);

    const countyCouncilFilterSchema = new FilterSchema([
        fields.name,
        fields.key,
        fields.tenant_id,
        fields.type,
    ]);

    export default {
        name: 'UrbanismRegulationsListFilter',
        data() {
            return {
                filters: null,
                currentPage: 1,
            };
        },
        created() {
            this.filters = this.selectedOrg.type === ORGANISATION_TYPE.COUNTY_COUNCIL ? countyCouncilFilterSchema.fields : filterSchema.fields;
        },
        computed: {
            ...mapGetters({
               total: 'administrator/urbanismRegulationZonesList/total',
               selectedOrg: "shared/organisation"
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'administrator/urbanismRegulationZonesList/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>

<style scoped>

</style>

