<template>
    <div class="mt-8">
        <st-page :showHeader="false">
            <UrbanismRegulationsListFilter ref="filter" >
                <template #toolbar>
                    <UrbanismRegulationsListToolbar @add="add"/>
                </template>
                <UrbanismRegulationsListTable
                    @edit="edit"
                    @update="update"
                    @manage="manage"
                >
                </UrbanismRegulationsListTable>
            </UrbanismRegulationsListFilter>
            <ManageUrbanismRegulationZonesModal
                ref="modal"
                :editMode="editMode"
                @update="update"
            />
            <ManageUrbanismRegulationsModal
                ref="manageModal"
            />
        </st-page>
    </div>
</template>
<script>
import UrbanismRegulationsListFilter from '@/modules/administrator/components/urbanism-regulations/UrbanismRegulationsListFilter';
import UrbanismRegulationsListTable from '@/modules/administrator/components/urbanism-regulations/UrbanismRegulationsListTable';
import UrbanismRegulationsListToolbar from '@/modules/administrator/components/urbanism-regulations/UrbanismRegulationsListToolbar';
import ManageUrbanismRegulationZonesModal from '@/modules/administrator/components/urbanism-regulations/ManageUrbanismRegulationZonesModal';
import ManageUrbanismRegulationsModal from '@/modules/administrator/components/urbanism-regulations/ManageUrbanismRegulationsModal';

export default {
    name: "UrbanismRegulationsList",
    components: {
        UrbanismRegulationsListFilter,
        UrbanismRegulationsListTable,
        UrbanismRegulationsListToolbar,
        ManageUrbanismRegulationZonesModal,
        ManageUrbanismRegulationsModal,
    },
    data() {
        return {
            editMode: false,
        }
    },
    methods: {
        add() {
            this.editMode = false;
            this.$refs.modal.show();
        },
        edit() {
            this.editMode = true;
            this.$refs.modal.show();
        },
        update() {
            this.$refs.filter.refresh();
        },
        manage() {
            this.$refs.manageModal.show();
        }
    },
};
</script>
<style scoped>

</style>


