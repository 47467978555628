import RelationToOneField from '@/shared/fields/relation-to-one-field';
import i18n from '@/shared/plugins/vue-i18n';

export class OrganisationTypeField {
    static relationToOne(name, label, rules, filterParams, children) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => 
                    resolve([
                        { value: 'county_council', name: i18n.t('INSTITUTION.ADD_EDIT_FORM.TYPES.COUNTY_COUNCIL') },
                        { value: 'city_hall', name: i18n.t('INSTITUTION.ADD_EDIT_FORM.TYPES.CITY_HALL')},
                        { value: 'services', name: i18n.t('INSTITUTION.ADD_EDIT_FORM.TYPES.SERVICES')},
                    ])
                );
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.value,
                    name: record.name,
                };
            },
            rules,
            filterParams,
            children
        );
    }
}
