<template>
    <div>
        <Loader v-if="loadingInfo"></Loader>
        <functionary-account-form
            v-if="!loadingInfo"
            @submitForm="editAccount"
            @cancelForm="cancelForm"
            id="edit-functionary-account"
            :headerTitle="$t('FUNCTIONARY.EDIT_ACCOUNT_HEADER')"
            :selectedAccount="functionaryAccount"
            :selectedInstitution="selectedInstitution"
            :editMode="true"
        >
            <template #buttons="{ submit, cancel }">
                <div class="col-3 text-right px-0">
                    <st-button
                        :disabled="isLoading"
                        :callback="submit"
                        :spinner="isLoading"
                        class="mr-5"
                    >
                        {{ $t("FORM_FIELDS.SAVE_BTN") }}
                    </st-button>
                    <st-button
                        variant="link"
                        :disabled="isLoading"
                        :callback="cancel"
                        :spinner="isLoading"
                    >
                        {{ $t("FORM_FIELDS.CANCEL_BTN") }}
                    </st-button>
                </div>
            </template>
        </functionary-account-form>
    </div>
</template>

<script>
import FunctionaryAccountForm from "@/modules/administrator/components/users/FunctionaryAccountForm.vue";
import { mapActions, mapGetters } from "vuex";
import Message from '@/shared/message/message';

export default {
    name: "EditFunctionaryAccount",
    components: { FunctionaryAccountForm },
    props: {
        selectedInstitution: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            selectedAccount: "administrator/form/getSelectedFunctionaryAccount",
            selectedProfile: "administrator/form/getSelectedFunctionaryProfile",
            loading: "shared/loading",
        }),
        isLoading(){
            return this.loading['administrator/patchFunctionaryAccount'];
        },
        loadingInfo() {
            return this.loading["administrator/getFunctionaryAccount"] || this.loading["administrator/getFunctionaryProfile"];
        },
        functionaryAccount() {
            const profileInfo = {
                identification_number: this.selectedProfile.identification_number,
                identification_issued_by: this.selectedProfile.identification_issued_by,
                external_enrollment_id: this.selectedProfile.external_enrollment_id,
            };

            return {...this.selectedAccount, ...profileInfo }
        }
    },
    methods: {
        ...mapActions({
            getFunctionaryAccount: "administrator/form/getFunctionaryAccount",
            updateFunctionaryAccount: "administrator/form/patchFunctionaryAccount",
            getFunctionaryProfile: "administrator/form/getFunctionaryProfile",
            addFunctionaryAssociations: "administrator/form/addFunctionaryAssociations",
        }),
        async editAccount(account) {
            let multipleAssoc = [];

            //check if department or function have changed to redo associations
            const departmentChanged = account.initialDep ? !account?.departments?.includes(account.initialDep?.name) : true;
            const functionChanged = account.initialFunc ? !account?.functions?.includes(account.initialFunc?.function_name) : true;

            // in case the value is missing, default it to false
            account.is_organisation_admin = account.is_organisation_admin ?? false;
            if (departmentChanged) {
                if (account.initialDep) {
                    multipleAssoc.push({
                        action: "delete",
                        parent_id: account.initialDep.id,
                        parent_type: "department",
                        child_id: account.user_id,
                        child_type: "user",
                        comment: `Removed user ${account.first_name} ${account.last_name} from department`,
                    }
                    );
                }

                if (account.department_id) {
                    multipleAssoc.push({
                        action: "add",
                        parent_id: account.department_id,
                        parent_type: "department",
                        child_id: account.user_id,
                        child_type: "user",
                        comment: `Added user ${account.first_name} ${account.last_name} to department`,
                    });
                }
            }

            if (functionChanged) {
                if (account.initialFunc) {
                    multipleAssoc.push({
                        action: "delete",
                        parent_id: account.initialFunc.function_id,
                        parent_type: "function",
                        child_id: account.user_id,
                        child_type: "user",
                        comment: `Removed user ${account.first_name} ${account.last_name} from function`,
                    });
                }

                if (account.function_id) {
                    multipleAssoc.push({
                        action: "add",
                        parent_id: account.function_id,
                        parent_type: "function",
                        child_id: account.user_id,
                        child_type: "user",
                        comment: `Added user ${account.first_name} ${account.last_name} to function`,
                    });
                }
            }

            const payload = {
                id: account.id,
                payload: account
            }

            //update account
            let updatedAccount = await this.updateFunctionaryAccount(payload);
            if (updatedAccount) {
                if (multipleAssoc.length > 0) {
                    await this.addFunctionaryAssociations(multipleAssoc);
                }

                Message.success('FORM_FIELDS.MESSAGES.ACCOUNT_UPDATED')
                this.cancelForm();
            } else {
                Message.error('FORM_FIELDS.MESSAGES.ACCOUNT_NOT_UPDATED');
            }
        },
        cancelForm() {
            this.$emit('cancelForm', true)
        },
    },
    async created() {
        const userId = this.$route.query.user;
        if (userId) {
            const user = await this.getFunctionaryAccount(userId);
            await this.getFunctionaryProfile(user.user_id);
        }
    },
};
</script>
