<template>
    <div>
        <st-filters-pagination
            :header="$t('TAXES.LIST.HEADER')"
            :total="total"
            :filters="filters"
            :moreFilters="false"
            :currentPage="currentPage"
            :showErase="false"
            @currentPage="currentPage = $event"
            @change="onChange"
        >
            <template #filters-toolbar>
                <slot name="toolbar">
                </slot>
            </template>
            
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
import { TaxModel } from './models/tax-model';
import { FilterSchema } from '@/shared/form/filter-schema';

const { fields } = TaxModel;

const filterSchema = new FilterSchema([
    fields.name,
    fields.category_id,
    fields.entry_date,
]);

export default {
    name: 'TaxesFilters',
    props: {
        total: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            filters: filterSchema.fields,
            currentPage: 1,
        }
    },
    methods: {
        onChange(params) {
            this.$emit('params', params);
        },
    },
}
</script>
