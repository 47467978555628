<template>
    <div>
        <st-button
            variant="secondary"
            :callback="add"
        >
            <i class="fa fa-plus"></i>
            {{ $t('URBANISM_REGULATION_ZONES.ADD_BUTTON') }}
        </st-button>
    </div>
</template>

<script>
    export default {
        name: 'UrbanismRegulationsListToolbar',
        methods: {
            add() {
                this.$emit('add');
            },
        }
    };
</script>

