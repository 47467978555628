<template>
    <div>
        <st-modal
            id="modal-users-accounts-by-type"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            hideable
            size="lg"
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{$t('ADMIN_USERS_MODAL.HEADER')}}</p>
            </template>
            <template #body>
                <users-list-table
                    @cancelModal="hide"
                    :selectedEntity="entity"
                    :institution="institution"
                >
                    <template #buttons="{ cancel }">
                        <div class="d-flex justify-content-between w-100">
                            <st-button
                                variant="link"
                                class="font-size-h6 px-5 py-3 my-3 mr-3"
                                :callback="cancel"
                            > {{ $t('GENERAL.BUTTON.CANCEL') }}
                            </st-button>
                        </div>
                    </template>
                </users-list-table>
            </template>
        </st-modal>
    </div>
</template>

<script>
import UsersListTable from './UsersListTable.vue';
export default {
    name: 'UsersModal',
    props: {
        entity: {
            type: Object,
            required: true
        },
        institution: {
            type: Object,
            required: true,
            default: () => ({ }),
        }
    },
    components: {
        UsersListTable
    },
    methods: {
        hide() {
            this.$refs['modal'].hide();
        },
        show() {
            this.$refs['modal'].show();
        },
    }
};
</script>
