<template>
    <form class="form"
        novalidate="novalidate"
        id="st_modal_department_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
            <label>
                {{ $t('DEPARTMENTS.ADD_EDIT_MODAL.NAME') }} *
            </label>
            <st-input-text
                v-model="department.name"
                ref="name"
                name="name"
            />
        </div>
        <div class="form-group">
            <label>
                {{ $t('DEPARTMENTS.ADD_EDIT_MODAL.DESCRIPTION') }}
            </label>
            <b-form-textarea
                v-model="department.description"
                id="textarea-description"
                :placeholder="$t('DEPARTMENTS.ADD_EDIT_MODAL.PLACEHOLDER')"
                ref="description"
                name="description"
            ></b-form-textarea>
        </div>
        <div class="mb-6">
            <label>
                {{ $t('DEPARTMENTS.ADD_EDIT_MODAL.STATUS') }}
            </label>
            <b-form-radio-group
                size="lg"
                v-model="department.status"
                :options="statusOptions"
                name="department-status"
            ></b-form-radio-group>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { DepartmentModel } from '@/modules/administrator/models/department-model';

const { fields } = DepartmentModel;
const formSchema = new FormSchema([
    fields.name
])

import Message from '@/shared/message/message';
import { mapActions } from 'vuex';
export default {
    name: 'ManageDepartmentForm',
    props: {
        selectedDepartment: {
            type: Object,
            required: true
        },
        selectedInstitution: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            department: {
                name: '',
                description: '',
                status: 'active',
                is_test: false
            },
            statusOptions: [
                { text: this.$t('DEPARTMENTS.ADD_EDIT_MODAL.STATUS_TYPE.ACTIVE'), value: 'active' },
                { text: this.$t('DEPARTMENTS.ADD_EDIT_MODAL.STATUS_TYPE.INACTIVE'), value: 'inactive' },
            ],
        };
    },
    mounted() {
        if(this.editMode) {
            this.department = { ...this.selectedDepartment };
        }
        this.fv = createFormValidation('st_modal_department_form', this.rules);
    },
    methods: {
        ...mapActions({
            addDepartment: 'administrator/depForm/addDepartment',
            updateDepartment: 'administrator/depForm/updateDepartment',
        }),
        clearModal() {
             this.department = {
                name: '',
                description: '',
                is_test: false,
                status: 'active',
            }
        },
        hide() {
            this.$emit('cancelForm');
            this.clearModal();
        },
        manageDepartment() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    if (this.editMode) {
                        this.onUpdateDepartment();
                    } else {
                        this.onAddDepartment();
                    }
                } else return false
            });
        },
        onAddDepartment() {
            const payload = {
                ...this.department,
                organisation_id: this.selectedInstitution.id,
                organisation_name: this.selectedInstitution.name
            }

            this.addDepartment(payload)
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('DEPARTMENTS.SUCCESS.ADD');
            })
        },
        onUpdateDepartment() {
             const payload = {
                name: this.department.name,
                description: this.department.description,
                status: this.department.status,
                is_test: this.department.is_test
            }

            this.updateDepartment({id: this.department.id, payload })
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('DEPARTMENTS.SUCCESS.UPDATE');
            })
        }
    }
};
</script>
