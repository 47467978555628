<template>
    <div>
        <st-modal
            id="st-modal-responsibility"
            hide-header-delimiter
            hide-footer-delimiter
            size="xl"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <responsibility-form
                    ref="responsibility-form"
                    :editMode="editMode"
                    :selectedInstitution="selectedInstitution"
                    :selectedResponsibility="selectedResponsibility"
                    @cancelForm="hide"
                    @submit="submit"
                    @responsibilitySelected="onResponsibilitySelected"
                    :isDestinationListNotEmpty.sync="isDestinationListNotEmpty"
                >
                </responsibility-form>
                <small>*{{clarificationText}}</small>
            </template  >
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageResponsibility"
                        :disabled="isUpdateDisabled"
                    >
                        <span>{{buttonText}}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ResponsibilityForm from './ResponsibilityForm.vue';
export default {
    name: 'ResponsibilityModal',
    components: {
        ResponsibilityForm
    },
    props: {
        selectedInstitution: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        selectedResponsibility: {
            type: Object,
            default: () => ({ }),
        }
    },
    data() {
        return {
            isResponsibilitySelected: false,
            isDestinationListNotEmpty: false,
        }
    },
    computed: {
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.UPDATE') : this.$t('GENERAL.BUTTON.ADD');
        },
        headerText() {
            return this.editMode ?
                this.$t('ORG_RESPONSIBILITIES.FORM.EDIT_TITLE') :
                this.$t('ORG_RESPONSIBILITIES.FORM.ADD_TITLE');
        },
        clarificationText() {
            return this.editMode ?
                this.$t('ORG_RESPONSIBILITIES.CLARIFICATION.EDIT') :
                this.$t('ORG_RESPONSIBILITIES.CLARIFICATION.ADD');
        },
        isUpdateDisabled() {
            return (!this.isResponsibilitySelected && !this.editMode) || !this.isDestinationListNotEmpty;
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.isResponsibilitySelected = false;
            this.$refs['modal'].hide();
        },
        manageResponsibility() {
            this.$refs['responsibility-form'].manageResponsibility();
        },
        submit() {
            this.$emit('updateResponsibility');
        },
        onResponsibilitySelected() {
            this.isResponsibilitySelected = true;
        }
    }
};
</script>

