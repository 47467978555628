<template>
    <div>
        <Loader v-if="isLoading"/>
        <st-page
            v-if="!isLoading"
            :showHeader="false"
            layout="tab"
        >
            <div v-if="!addAccount && !editAccount && !assignUserToUAT">
                <functionary-list-filter
                    :user_type_id="user_type_id"
                    ref="listFunctionaryFilter"
                >
                    <template #toolbar>
                        <st-button
                            v-can:create="'organisationsStaffUsers'"
                            variant="primary"
                            :callback="addUserAccount"
                            v-if="!addAccount && !editAccount"
                        >
                            <i class="fa fa-plus"></i>
                            {{ $t('FUNCTIONARY.ADD_BTN') }}
                        </st-button>
                    </template>
                    <functionary-list-table
                        :selectedInstitution="selectedInstitution"
                        @editFunctionary="editFunctionary"
                        @updateFunctionaryList="updateFunctionaryList"
                        @assignUserToUAT="doAssignUserToUAT"
                    />
                </functionary-list-filter>
            </div>
            <div v-if="addAccount">
                <add-functionary-account
                    @cancelForm="cancelForm"
                    :selectedInstitution="selectedInstitution"
                    :user_type_id="staff_user_type_id"
                ></add-functionary-account>
            </div>
            <div v-if="editAccount">
                <edit-functionary-account
                    @cancelForm="cancelForm"
                    :selectedInstitution="selectedInstitution"
                ></edit-functionary-account>
            </div>
            <div v-if="assignUserToUAT">
                <territorial-unit-allocation-list-filter
                    ref="territorial-unit-allocation-list-filter"
                    @cancelForm="cancelForm"
                    @addUAT="addUAT"
                    :user="selectedUSer"
                >
                    <territorial-unit-allocation-list-table @UatUpdated="UatUpdated"/>
                </territorial-unit-allocation-list-filter>
            </div>
        </st-page>
        <add-user-to-UAT-modal
            ref="addUserToUATModal"
            :user="selectedUSer"
            @UatUpdated="UatUpdated"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import FunctionaryListFilter from '@/modules/administrator/components/users/FunctionaryListFilter';
    import FunctionaryListTable from '@/modules/administrator/components/users/FunctionaryListTable';
    import AddFunctionaryAccount from '@/modules/administrator/components/users/AddFunctionaryAccount.vue';
    import EditFunctionaryAccount from '@/modules/administrator/components/users/EditFunctionaryAccount.vue';
    import TerritorialUnitAllocationListFilter from '@/modules/administrator/components/territorial-unit-allocation/TerritorialUnitAllocationListFilter.vue';
    import TerritorialUnitAllocationListTable from '@/modules/administrator/components/territorial-unit-allocation/TerritorialUnitAllocationListTable.vue';
    import AddUserToUATModal from '@/modules/administrator/components/territorial-unit-allocation/AddUserToUATModal';

    export default {
        name: 'FunctionaryList',
        props: {
            selectedInstitution: {
                type: Object,
                required: true
            }
        },
        components: {
            FunctionaryListFilter,
            FunctionaryListTable,
            AddFunctionaryAccount,
            EditFunctionaryAccount,
            TerritorialUnitAllocationListFilter,
            TerritorialUnitAllocationListTable,
            AddUserToUATModal
        },
        data() {
            return {
                selectedFunction: {},
                staff_user_type_id: '',
                user_type_id: [],
                addAccount: false,
                editAccount: false,
                assignUserToUAT: false,
                selectedUSer: {}
            };
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading'
            }),
            isLoading () {
                return this.loading['shared/getUserTypes'] ;
            },
        },
         created() {
            this.resetFilters();
            //get user types and fetch related users
            this.fetchUserTypes().then((result) => {
                this.user_type_id = result.data.filter((item) => {
                    return (item.name === 'staff' || item.name === 'admin');
                }).map((el) => el.id);
                this.staff_user_type_id = result.data.find((item) => {
                    return item.name === 'staff';
                })?.id;
            });
        },
        mounted() {
            if (this.$route.query.user) {
                this.editAccount = true;
            }
        },
        methods: {
            ...mapActions({
                fetchUserTypes: 'shared/getUserTypes',
                resetFilters: 'administrator/list/doResetFilters'
            }),
            addUserAccount (){
                this.addAccount = true;
            },
            cancelForm(editMode) {
                this.resetFilters();
                this.assignUserToUAT = false;
                if (editMode) {
                    this.editAccount = false;
                    this.$router.push({
                        name: 'institution',
                        query: {
                            id: this.$route.query.id,
                            tab: this.$route.query.tab,
                        },
                    });
                } else {
                    this.addAccount = false;
                }
            },
            editFunctionary(data) {
                this.editAccount = true;
                this.$router.push({
                    name: 'institution',
                    query: { ...this.$route.query, ...{ user: data}},
                });
            },
            updateFunctionaryList() {
                this.$refs.listFunctionaryFilter.refresh();
            },
            doAssignUserToUAT(user) {
                this.selectedUSer = user;
                this.assignUserToUAT = true;
            },
            addUAT(user) {
                this.selectedUSer = user;
                this.$refs.addUserToUATModal.show();
            },
            UatUpdated(){
                this.$refs['territorial-unit-allocation-list-filter'].refresh();
            }
        }
    }
</script>
