
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { DecisionTargetService } from '@/modules/decision-target/decision-target-service';

export class DecisionTargetField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            DecisionTargetService.listAutocomplete,
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
