<template>
    <div>
        <st-data-table
            :items="taxes"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            hover
            @edit="onEdit"
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TaxModel } from './models/tax-model';

const { fields } = TaxModel;

export default {
    name: 'TaxesTable',
    data() {
        return {
            presenter: TaxModel.presenter,
            fields: [
                fields.name,
                fields.entry_date,
                fields.justification,
            ],
        }
    },
    computed: {
        ...mapGetters({
            taxes: 'administrator/taxes',
            orgPermissions: 'administrator/orgList/orgPermissions',
        }),
        actions() {
            const actions = [];
            if (this.orgPermissions.hasPermissionToEditTax) {
                    actions.push({
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('TAXES.LIST.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    })
                }
            return actions
        }
    },
    methods: {
        onEdit(item) {
            this.$emit('edit', item.item);
        },
    },
}
</script>
