<template>
    <form class="form"
        novalidate="novalidate"
        id="st_add_user_to_uat_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
            <label class="font-size-h6">
                {{ fields.territorial_administrative_unit_id.label }} *
            </label>
            <st-autocomplete
                v-model="model[fields.territorial_administrative_unit_id.name]"
                :options="options"
                :ref="fields.territorial_administrative_unit_id.name"
                :name="fields.territorial_administrative_unit_id.name"
                @change="onChange(fields.territorial_administrative_unit_id)"
            ></st-autocomplete>
        </div>
        <small class="mt-8">* {{ $t('TERRITORIAL_UNIT_ALLOCATION.MODAL.OBS') }}</small>
    </form>
</template>

<script>
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { TerritorialUnitAllocationModel } from '@/modules/administrator/models/territorial-unit-allocation-model';
import { mapActions, mapGetters } from 'vuex';

const { fields } = TerritorialUnitAllocationModel;

const formSchema = new FormSchema([
    fields.territorial_administrative_unit_id,
]);

export default {
    name: 'AddUserToUATForm',
    props: {
        user:{
            type: Object,
            required: true
        }
    },
    data: () => ({
        rules: formSchema.rules(),
        model: null,
        fields,
        options: [],
    }),
    computed: {
        ...mapGetters({
            territorialUnits: 'administrator/territorialUnitAllocation/rows',
        }),

    },
     created() {
       this.fields.territorial_administrative_unit_id.updateOptions()
       .then (() => {
            const uatList = this.fields.territorial_administrative_unit_id.options;
            // Display localities that are not already added on staff (assigned UAT's)
            this.options = uatList.filter(el=> {
                return !this.territorialUnits.find(unit => {
                    return unit.tu_id === el.value;
                });
            })
       })
       this.model = formSchema.initialValues({});
    },
    mounted() {
        this.fv = createFormValidation('st_add_user_to_uat_form', this.rules);
    },
    methods: {
        ...mapActions({
            addUatToStaff: 'administrator/territorialUnitAllocationForm/addUatToStaff'
        }),
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
        async addUAT() {
           const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const cast = formSchema.cast(this.model);
                const selectedUAT = this.fields.territorial_administrative_unit_id.options.find((el) => el.value === cast.territorial_administrative_unit_id);
                const payload = {
                    user_id: this.user.user_id,
                    user_email:  this.user.user_email,
                    tu_id: selectedUAT.value,
                    tu_name: selectedUAT.text,
                    user_name: `${this.user.first_name} ${this.user.last_name}`,
                    active: true
                }
                await this.addUatToStaff(payload);
                this.$emit('submit');
            }
        }
    }
}
</script>
